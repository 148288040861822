
































































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { Toggle } from '@/app/components';
import { unitTransformations } from '@/app/constants';
import { useDataModel } from '../composable';
import ChangeIndication from './ChangeIndication.vue';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

export default defineComponent({
    name: 'Metadata',
    components: { ChangeIndication, ValidationProvider, Toggle },
    props: {
        dataType: {
            type: String,
            required: true,
        },
        id: {
            type: [Number, String],
            required: false,
        },
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        parentMetadata: {
            type: Object,
            required: false,
        },
        isProposal: {
            type: Boolean,
            required: false,
        },
        initialMetadata: {
            type: Object,
            required: false,
        },
        conceptStatus: {
            type: String,
            required: false,
        },
        isDraft: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChange: {
            type: Boolean,
            required: false,
        },
    },
    setup(props, { emit }) {
        const { fixDatatypeMetadata, defineDatatypeMetadata } = useDataModel();
        const indexOptions = [
            { label: 'Keyword', value: 'keyword' },
            { label: 'Text', value: 'text' },
            { label: 'Both Keyword and Text', value: 'both' },
            { label: 'False', value: false },
        ];
        const unitKeys = Object.keys({ ...unitTransformations, 'Not relevant': {}, None: {} });

        const type = ref<any>(null);
        const inField = ref<boolean>(false);

        const metadata = ref<any>(null);

        const updateMetadata = () => {
            if (!metadata.value.multiple && metadata.value.ordered) {
                metadata.value.ordered = false;
            }
            emit('update-metadata', metadata.value);
        };

        const showSpatial = computed(() => {
            switch (type.value) {
                case 'integer':
                case 'double':
                case 'string':
                    return true;
                default:
                    return false;
            }
        });

        const showDropdownIndexOrSpatialID = computed(() => {
            return type.value === 'string';
        });

        const showToggleIndex = computed(() => {
            switch (type.value) {
                case 'datetime':
                case 'date':
                case 'time':
                case 'integer':
                case 'double':
                case 'boolean':
                    return true;
                default:
                    return false;
            }
        });

        const showBooleanMeta = computed(() => {
            return type.value === 'boolean';
        });

        const showTemporal = computed(() => {
            switch (type.value) {
                case 'datetime':
                case 'date':
                case 'time':
                    return true;
                default:
                    return false;
            }
        });

        const showMeasurement = computed(() => {
            if (
                !props.parentMetadata ||
                (props.parentMetadata && metadata.value.measurementType) ||
                (props.parentMetadata && (props.isProposal || props.isDraft)) ||
                (props.parentMetadata && !metadata.value.measurementType && metadata.value.measurementUnit)
            ) {
                switch (type.value) {
                    case 'integer':
                    case 'double':
                        return true;
                    default:
                        return false;
                }
            }
            return false;
        });

        const defineMetadataObject = (args: any) => {
            type.value = { ...args }['0'];
            inField.value = false;
            if (!props.parentMetadata) {
                metadata.value = defineDatatypeMetadata(type.value);
                updateMetadata();
            } else {
                metadata.value = fixDatatypeMetadata(R.clone(props.parentMetadata), type.value);
            }
        };

        watch(
            () => [props.dataType, props.id],
            (args) => {
                defineMetadataObject(args);
            },
        );
        defineMetadataObject([props.dataType, props.id]);

        const showMetadataChangeIndication = computed(() => {
            return !!props.changesToBeSaved.find((c: any) => c.change === 'metadata') || props.savedChange;
        });

        const disableMultiple = computed(() => {
            return (
                (props.parentMetadata && props.initialMetadata?.multiple && !props.isProposal && !props.isDraft) ||
                props.readOnly
            );
        });

        const disableOrdered = computed(() => {
            return (
                !metadata.value.multiple ||
                (props.parentMetadata &&
                    props.initialMetadata?.multiple &&
                    !props.initialMetadata?.ordered &&
                    !props.isProposal &&
                    !props.isDraft) ||
                props.readOnly
            );
        });

        const disableForBinaryType = computed(() => type.value === 'base64binary');

        const disableSensitive = computed(() => {
            return (
                (props.parentMetadata && !props.initialMetadata?.sensitive && !props.isProposal && !props.isDraft) ||
                props.readOnly ||
                disableForBinaryType.value
            );
        });

        const fixToggleHalfLayout = computed(() => {
            return (
                !showSpatial.value &&
                (!showDropdownIndexOrSpatialID.value || showToggleIndex.value) &&
                showTemporal.value &&
                !showToggleIndex.value
            );
        });

        const enableMeasurementType = computed(() => {
            return (
                // eslint-disable-next-line no-useless-escape
                (props.parentMetadata && metadata.value.measurementType === '\s*Not relevant\s*') ||
                (!metadata.value.measurementType && metadata.value.measurementUnit)
            );
        });

        const resetMetadataMeasurements = () => {
            metadata.value.measurementUnit = null;
            if (metadata.value.measurementType === 'None') {
                metadata.value.measurementType = null;
            }
            updateMetadata();
        };

        const showMeasurementUnit = computed(() => {
            return (
                (metadata.value.measurementType ||
                    (!metadata.value.measurementType && metadata.value.measurementUnit)) &&
                metadata.value.measurementType !== 'None'
            );
        });

        const disableMeasurementUnit = computed(() => {
            return (
                props.parentMetadata &&
                !props.isDraft &&
                !props.isProposal &&
                (metadata.value.measurementType === 'Not relevant' || !metadata.value.measurementType) &&
                metadata.value.measurementUnit
            );
        });

        const enableFreeTextMeasurementUnit = computed(() => {
            return (
                ((!props.parentMetadata || props.isDraft || props.isProposal) &&
                    metadata.value.measurementType === 'Not relevant') ||
                (!metadata.value.measurementType && metadata.value.measurementUnit)
            );
        });

        return {
            disableForBinaryType,
            disableMeasurementUnit,
            disableMultiple,
            disableOrdered,
            disableSensitive,
            enableFreeTextMeasurementUnit,
            enableMeasurementType,
            fixToggleHalfLayout,
            indexOptions,
            inField,
            metadata,
            resetMetadataMeasurements,
            showBooleanMeta,
            showDropdownIndexOrSpatialID,
            showMeasurement,
            showMeasurementUnit,
            showMetadataChangeIndication,
            showSpatial,
            showTemporal,
            showToggleIndex,
            unitKeys,
            unitTransformations,
            updateMetadata,
        };
    },
});
