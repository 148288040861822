
















































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import VueTagsInput from '@johmun/vue-tags-input';
import { Card } from '@/app/components';
import { Status, SuggestionStatus } from '../constants';
import ChangeIndication from './ChangeIndication.vue';

export default defineComponent({
    name: 'HighlevelConceptDetails',
    props: {
        selectedHighLevelConcept: {
            type: Object,
            required: true,
        },
        isProposal: {
            type: Boolean,
            default: false,
        },
        underEditing: {
            type: Boolean,
            default: false,
        },
        parentStatus: {
            type: String,
        },
        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChanges: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Card,
        ChangeIndication,
        VueTagsInput,
    },
    setup(props, { emit }) {
        // UI variables
        const tag = ref('');

        const tags = computed(() => {
            const tmpArray = [] as any;
            if (props.selectedHighLevelConcept.relatedTerms) {
                props.selectedHighLevelConcept.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));
            }

            return tmpArray;
        });

        const showChangeInParentConcept = (c: any) =>
            (c.concept === props.selectedHighLevelConcept.id || c.parentId === props.selectedHighLevelConcept.id) &&
            props.selectedHighLevelConcept.status !== SuggestionStatus.Pending;

        const saved = computed(() => {
            // show saved change if the id of the concept is in saved changes and it's not pending
            return !!props.savedChanges.find((c: any) => showChangeInParentConcept(c));
        });

        const unsavedChanges = computed(() => {
            // show unsaved change if the id of the concept is in changes to be saved and it's not pending
            return !!props.changesToBeSaved.find((c: any) => showChangeInParentConcept(c));
        });

        return {
            emit,
            saved,
            Status,
            tag,
            tags,
            unsavedChanges,
        };
    },
});
