






















































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ConfirmButton } from '@/app/components';
import ChangeIndication from './ChangeIndication.vue';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

export default defineComponent({
    name: 'StandardsMapping',
    props: {
        conceptStandardsMapping: {
            type: Array,
            required: true,
        },
        standards: {
            type: Array,
            default: () => [],
        },
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        requiresAttention: {
            type: Boolean,
            default: false,
        },
        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChange: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ChangeIndication,
        ConfirmButton,
        ValidationObserver,
        ValidationProvider,
    },
    setup(props, { emit }) {
        // UI variables
        const standardsMappingRef = ref<any>(null);
        const standardsMapping = ref<any>(R.clone(props.conceptStandardsMapping));
        const tmpStandardsVersions = ref<any>(R.clone(props.conceptStandardsMapping));
        const editStandardsMapping = ref<any>(null);
        const addNewStandardsMapping = ref<any>(false);
        const cancelStandardsMappingChanges = ref<any>(null);
        const newStandard = ref(false);
        const userDefinedStandard = ref(false);
        const extraStandard = ref(false);
        const validateStandard = ref(false);
        const versionsOfStandard = ref<any>(null);

        const filteredStandardsMapping = computed(() => {
            return standardsMapping.value;
        });

        const findVersions = (standard: string) => {
            if (standard) {
                versionsOfStandard.value = props.standards.filter((s: any) => s.standard === standard);

                const tmpVersion: any = tmpStandardsVersions.value.filter((s: any) => s.standard === standard);
                if (
                    versionsOfStandard.value.length &&
                    tmpVersion.length &&
                    tmpVersion.version !== null &&
                    tmpVersion[0].version !== versionsOfStandard.value[0].version
                ) {
                    versionsOfStandard.value = [...versionsOfStandard.value, ...tmpVersion].sort((a: any, b: any) => {
                        return a.version.toLowerCase() > b.version.toLowerCase() ? 1 : -1;
                    });
                }
            } else {
                versionsOfStandard.value = null;
            }
        };

        const editStandard = (idx: number, standardMapping: any) => {
            editStandardsMapping.value = idx;
            cancelStandardsMappingChanges.value = R.clone(standardMapping); // user cancels changes => replace them with old values

            extraStandard.value = !props.standards.find((s: any) => s.standard === standardMapping.standard); // check if this standard is a new one i.e. does not exist in the model standards list
            validateStandard.value = false;

            findVersions(standardMapping.standard);
            emit('done-editing', false);
        };

        const cancelEditing = (index: number, mappings: any) => {
            editStandardsMapping.value = null;
            newStandard.value = false;

            if (addNewStandardsMapping.value) {
                mappings.pop();

                addNewStandardsMapping.value = false;
                versionsOfStandard.value = null;
            } else {
                standardsMapping.value[index].name = cancelStandardsMappingChanges.value.name;
                standardsMapping.value[index].version = cancelStandardsMappingChanges.value.version;
                standardsMapping.value[index].standard = cancelStandardsMappingChanges.value.standard;
                standardsMapping.value[index].type = cancelStandardsMappingChanges.value.type;
            }
            emit('done-editing', true);
        };

        const addStandardsMapping = (mappings: any) => {
            if (!mappings) {
                standardsMapping.value = [{ name: null, standard: null, version: null, type: null }];
                editStandardsMapping.value = 0;
            } else {
                standardsMapping.value.push({ name: null, standard: null, version: null, type: null });
                editStandardsMapping.value = mappings.length - 1;
            }
            emit('done-editing', false);
        };

        const removeStandardsMapping = (index: number) => {
            if (standardsMapping.value.length === 1) {
                standardsMapping.value = [];
            } else {
                standardsMapping.value.splice(index, 1);
            }

            emit('updatedStandardsMapping', standardsMapping.value);
            emit('done-editing', true);
        };

        const doneEditing = (mappings: any) => {
            if (addNewStandardsMapping.value) {
                const standardMapping = mappings[mappings.length - 1];
                if (
                    standardMapping.name === null &&
                    standardMapping.standard === null &&
                    standardMapping.type === null
                ) {
                    mappings.pop();
                }
                addNewStandardsMapping.value = false;
                emit('done-editing', true);
            } else {
                emit('done-editing', true);
            }

            standardsMappingRef.value.forEach((v: any) => v.reset());
            newStandard.value = false;
            editStandardsMapping.value = null;
        };

        const checkIfNewStandard = (standard: string) => {
            newStandard.value = standard === 'Add a new standard';
        };

        const filteredStandards = (option: any) => {
            const tmpStandards = props.standards.filter((s: any) => {
                if (!standardsMapping.value.filter((ss: any) => ss.standard === s.standard).length) {
                    return s;
                }
                return null;
            });
            if (option.standard) {
                return tmpStandards.concat({ standard: option.standard, version: option.version });
            }
            return tmpStandards;
        };

        const standardAlreadyExists = computed(() => {
            for (let i = 0; i < filteredStandardsMapping.value.length - 1; i++) {
                for (let j = i + 1; j < filteredStandardsMapping.value.length; j++) {
                    if (
                        filteredStandardsMapping.value[i].standard === filteredStandardsMapping.value[j].standard &&
                        filteredStandardsMapping.value[i].standard !== null
                    ) {
                        return true;
                    }
                }
            }
            return false;
        });

        const showStandardsMappingChangeIndication = computed(() => {
            return !!props.changesToBeSaved.find((c: any) => c.change === 'standardsMapping') || props.savedChange;
        });

        const changeStandardResetVersions = (index: number) => {
            standardsMapping.value[index].version = null;

            if (standardsMapping.value[index].standard === 'Add a new standard') {
                standardsMapping.value[index].standard = null;
                newStandard.value = true;
                userDefinedStandard.value = true;
            } else {
                findVersions(standardsMapping.value[index].standard);
            }
        };

        const standardExistsInBothLists = computed(() => {
            // checks whether the standard which is trying to be added exists in either the model's standard list or in the standards mapping list of this concept
            return editStandardsMapping.value !== null
                ? props.standards.find(
                      (s: any) => s.standard === standardsMapping.value[editStandardsMapping.value].standard,
                  ) || standardAlreadyExists.value
                : false;
        });

        const disableDoneEditing = computed(() => {
            return editStandardsMapping.value !== null
                ? standardAlreadyExists.value ||
                      (newStandard.value &&
                          props.standards.find(
                              (s: any) => s.standard === standardsMapping.value[editStandardsMapping.value].standard,
                          ))
                : null;
        });
        return {
            addStandardsMapping,
            addNewStandardsMapping,
            cancelEditing,
            cancelStandardsMappingChanges,
            changeStandardResetVersions,
            checkIfNewStandard,
            disableDoneEditing,
            doneEditing,
            editStandard,
            editStandardsMapping,
            emit,
            extraStandard,
            filteredStandards,
            filteredStandardsMapping,
            findVersions,
            newStandard,
            removeStandardsMapping,
            showStandardsMappingChangeIndication,
            standardAlreadyExists,
            standardExistsInBothLists,
            standardsMapping,
            standardsMappingRef,
            userDefinedStandard,
            validateStandard,
            versionsOfStandard,
        };
    },
});
