var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"modals"}},[(_vm.editModel || _vm.unblockEdit)?_c('form-modal',{attrs:{"title":_vm.readOnly ? 'Model Details' : 'Update Model',"saveButtonText":_vm.readOnly ? 'OK' : 'Update',"width":"xl:w-1/2 sm:w-2/3 xs:w-full","allowSave":_vm.changesToBeSaved.length > 0,"loading":_vm.loading},on:{"cancel":_vm.cancelChanges,"save":_vm.saveOrView},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('orbit-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"animation-duration":1000,"size":32,"color":"#5C69D1"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"ml-3 text-neutral-600"},[_vm._v("Saving model details...")])]},proxy:true}],null,false,344418429)},[_c('edit-model',{attrs:{"createModel":_vm.isCurrentModelDraft,"read-only":_vm.readOnly,"error":_vm.updateModelError,"changesToBeSaved":_vm.changesToBeSaved,"savedChanges":_vm.savedChanges},on:{"change":_vm.change},model:{value:(_vm.clonedModel),callback:function ($$v) {_vm.clonedModel=$$v},expression:"clonedModel"}}),_c('hr',{staticClass:"mt-4"})],1):_vm._e()],1),_c('div',{staticClass:"flex flex-row space-x-4 w-full cursor-pointer",on:{"mouseenter":function($event){_vm.showDetails = true},"mouseleave":function($event){_vm.showDetails = false},"click":function($event){_vm.changesToBeSaved.length ? _vm.emit('blockEditIfChanges') : (_vm.editModel = true)}}},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"capitalize flex items-center",class:{
                    ' text-neutral-600': _vm.model.status === _vm.Status.Deprecated,
                    'text-neutral-800': _vm.model.status !== _vm.Status.Deprecated,
                }},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.model.name)+" ")])]),_c('div',{staticClass:"text-lg flex items-center"},[_c('span',{staticClass:"px-2 py-1 rounded flex",class:{
                        'text-blue-700 bg-blue-200': _vm.model.status === _vm.Status.Draft,
                        'text-green-700 bg-green-200': _vm.model.status === _vm.Status.Stable && !_vm.isUnderRevision,
                        'text-neutral-500 bg-neutral-200': _vm.model.status === _vm.Status.Deprecated,
                        'text-orange-700 bg-orange-200 ': _vm.model.status === _vm.Status.UnderRevision,
                        'text-red-700 bg-red-200': _vm.isUnderRevision,
                    }},[(_vm.isUnderRevision)?_c('div',{staticClass:"flex flex-row items-center text-center"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"}})]),_c('span',{staticClass:"text-center w-full"},[_vm._v(_vm._s(_vm.model.version)+" - Stable")])]):[_vm._v(_vm._s(_vm.model.version)+" - "+_vm._s(_vm.model.status === _vm.Status.UnderRevision ? 'In Review' : _vm.model.status))]],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetails),expression:"showDetails"}],staticClass:"text-neutral-500 text-base truncate flex items-center",attrs:{"title":_vm.model.description}},[_vm._v(" "+_vm._s(_vm.model.description)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }