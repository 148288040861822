










































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import VueTagsInput from '@johmun/vue-tags-input';
import { TwButton, Card, ConfirmButton, Scrollbar } from '@/app/components';
import StandardsMapping from './StandardsMapping.vue';
import Metadata from './Metadata.vue';
import { ModelsAPI } from '../api';
import { Status } from '../constants';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

export default defineComponent({
    name: 'CreateConcept',
    props: {
        standards: {
            type: Array,
            required: false,
        },
        highLevelConcept: {
            type: Boolean,
            required: true,
        },
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        parent: {
            type: Object,
            required: true,
        },
        highLevelConcepts: {
            type: Array,
            required: false,
        },
        isDraft: {
            type: Boolean,
            default: false,
        },
        isUnderRevision: {
            type: Boolean,
            default: false,
        },
        stableModels: {
            type: Array,
            required: true,
        },
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        TwButton,
        VueTagsInput,
        Card,
        ConfirmButton,
        Scrollbar,
        StandardsMapping,
        Metadata,
    },
    setup(props, { root, emit }) {
        // UI variables
        const loading = ref(false);
        const newConceptRef = ref<any>(null);
        const hovered = ref(false);
        const editPrefixDescription = ref<any>(null);
        const relatedTermError = ref<string | null>(null);
        const cancelPrefix = ref({ prefix: null, description: null });
        const newRelatedConcept = ref({ prefix: null, description: null });
        const selectedModelForConceptToBeCopied = ref<number | null>(null);
        const selectedDataModelHLConcepts = ref<[]>([]);
        const chooseDataModel = ref<string>('new_concept');
        const addNewRelatedConcept = ref(false);
        const tag = ref('');
        let key = 0;

        const filteredActiveHLConcepts = computed<any>(() => {
            if (!props.highLevelConcepts) return [];

            if (props.isDraft) {
                return props.highLevelConcepts.filter((h: any) => h.status === Status.Draft);
            }

            if (props.isUnderRevision) {
                return props.highLevelConcepts.filter(
                    (h: any) => h.status === Status.UnderRevision || h.status === Status.Draft,
                );
            }

            return props.highLevelConcepts.filter((h: any) => h.status === Status.Stable);
        });

        const activeHLConcepts = computed(() => {
            return selectedDataModelHLConcepts.value;
        });
        const newConcept = ref<any>({});
        const selectedConceptToBeCopied = ref(null);
        const changeModel = async () => {
            if (selectedModelForConceptToBeCopied.value) {
                await ModelsAPI.getConcepts(selectedModelForConceptToBeCopied.value).then((res: any) => {
                    selectedDataModelHLConcepts.value = res.data.filter((h: any) => h.status === Status.Stable);
                });
            }
            selectedConceptToBeCopied.value = null;
        };

        const changeConcept = (highLevelConcept: boolean) => {
            newConcept.value = {
                name: null,
                description: null,
                relatedTerms: [],
                standardsMapping: null,
                referenceConceptName: null,
                referenceConceptId: null,
                referencePrefix: [],
                parentId: props.parent.id,
                status: props.parent.status,
                metadata: null,
                tokenizedTerms: null,
                type: highLevelConcept ? 'object' : null,
                version: props.parent.version,
                domainUid: highLevelConcept ? props.parent.uid : props.parent.domainUid,
            };
            if (newConceptRef.value !== null) {
                newConceptRef.value.reset();
            }
            tag.value = '';
            selectedModelForConceptToBeCopied.value = null;
            selectedConceptToBeCopied.value = null;
        };

        watch(
            () => props.highLevelConcept,
            (highLevelConcept) => changeConcept(highLevelConcept),
        );
        changeConcept(props.highLevelConcept);

        const types = computed(() => {
            if (!props.highLevelConcept) {
                return [
                    { name: 'object', value: 'object' },
                    { name: 'boolean', value: 'boolean' },
                    { name: 'date', value: 'date' },
                    { name: 'datetime', value: 'datetime' },
                    { name: 'double', value: 'double' },
                    { name: 'integer', value: 'integer' },
                    { name: 'string', value: 'string' },
                    { name: 'time', value: 'time' },
                    { name: 'binary', value: 'base64binary' },
                ];
            }
            return [{ name: 'object', value: 'object' }];
        });

        const changeTags = (newTags: any) => {
            relatedTermError.value = null;
            const tmpTags = newTags.map((t: any) => t.text);
            newConcept.value.relatedTerms = tmpTags;
        };

        const tags = computed(() => {
            const tmpArray = [] as any;
            newConcept.value.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));

            return tmpArray;
        });

        const createConcept = async () => {
            if (await newConceptRef.value.validate()) {
                if (!(newConcept.value.name.trim()[0] === '@')) {
                    loading.value = true;

                    if (selectedConceptToBeCopied.value) {
                        newConcept.value.conceptToBeCopiedId = selectedConceptToBeCopied.value;
                        emit(
                            'display-loading-modal',
                            props.parent.status === Status.Stable ? 'clone-and-copy' : 'copy',
                        );

                        ModelsAPI.copyConcept(newConcept.value)
                            .then((res: any) => {
                                (root as any).$toastr.s('Concept is created successfully', 'Success');

                                if (!res.data.parentId) {
                                    // cloned model returned
                                    if (res.data && newConcept.value.parentId !== res.data.id) {
                                        root.$router.push({ name: 'model-manager:edit', params: { id: res.data.id } });
                                    }
                                    emit('created-copied-concept');
                                } else {
                                    // copied high level concept returned
                                    emit('created-high-level-concept', res.data);
                                }
                                emit('display-loading-modal', '');
                            })
                            .catch((e) => {
                                emit('display-loading-modal', '');
                                const message = e?.response?.data?.message ? `: ${e.response.data.message}` : '';
                                (root as any).$toastr.e(`Concept failed to be created${message}`, 'Error');
                            })
                            .finally(() => {
                                loading.value = false;
                            });
                    } else {
                        ModelsAPI.createConcept(newConcept.value)
                            .then((res: any) => {
                                (root as any).$toastr.s(
                                    `${props.highLevelConcept ? 'Concept' : 'Field'} '${
                                        newConcept.value.name
                                    }' is created successfully`,
                                    'Success',
                                );
                                if (props.highLevelConcept) {
                                    emit('created-high-level-concept', res.data);
                                } else {
                                    emit('created-field', res.data);
                                }
                            })
                            .catch((e) => {
                                const message = e?.response?.data?.message ? `: ${e.response.data.message}` : '';
                                (root as any).$toastr.e(
                                    `${props.highLevelConcept ? 'Concept' : 'Field'} failed to be created${message}`,
                                    'Error',
                                );
                            })
                            .then(() => {
                                loading.value = false;
                            });
                    }
                } else {
                    (root as any).$toastr.w(
                        `Concept failed to be created: The names of High Level Concepts cannot start with the special character '@'. Please rename the concept.`,
                        'Invalid name',
                    );
                }
            }
        };

        const updateStandardsMapping = (updatedMappings: any) => {
            newConcept.value.standardsMapping = updatedMappings;
        };

        const addRelatedConcept = (relatedConcepts: any) => {
            relatedConcepts.push(newRelatedConcept.value);
            newRelatedConcept.value = { prefix: null, description: null };
            addNewRelatedConcept.value = false;
        };

        const removeRelatedConcept = (index: number) => {
            newConcept.value.referencePrefix.splice(index, 1);
        };

        const componentKey = computed(() => {
            key += 1;
            return newConcept.value ? key : null;
        });

        const updateMetadata = (metadata: any) => {
            newConcept.value.metadata = metadata;
        };

        const validateReferencePrefix = computed(() => {
            return newConcept.value.type === 'object' ? !!newConcept.value.referencePrefix.length : true;
        });

        const duplicateAdded = (entry: { text: string }) => {
            relatedTermError.value = `Related term '${entry.text}' already exists`;
        };

        const resetReferenceConcept = () => {
            if (newConcept.value.type !== 'object') {
                newConcept.value.referenceConceptName = null;
                newConcept.value.referencePrefix = [];
                newConcept.value.referenceConceptId = null;
                addNewRelatedConcept.value = false;
            } else {
                newConcept.value.referencePrefix = [];
            }
        };

        const openAddNewRelatedConcept = () => {
            const referenceConcept = filteredActiveHLConcepts.value.find(
                (h: any) => h.id === newConcept.value.referenceConceptId,
            );
            newConcept.value.referenceConceptName = referenceConcept.name;
            addNewRelatedConcept.value = true;
            newRelatedConcept.value = { prefix: null, description: null };
        };

        const openEditRelatedConcept = (index: number, referencePrefix: any) => {
            editPrefixDescription.value = index;
            cancelPrefix.value.prefix = referencePrefix.prefix;
            cancelPrefix.value.description = referencePrefix.description;
        };

        const enableAddNewRelatedConcept = computed(() => {
            return (
                addNewRelatedConcept.value ||
                (newConcept.value.referenceConceptId && !newConcept.value.referencePrefix.length)
            );
        });

        const cancelAddNewRelatedConcept = () => {
            addNewRelatedConcept.value = false;
            newRelatedConcept.value.prefix = null;
            newRelatedConcept.value.description = null;
        };

        const disableCreateConcept = computed(() => {
            return loading.value || (!validateReferencePrefix.value && !props.highLevelConcept);
        });

        const placeholderText = computed(() => {
            return {
                name: `Enter ${props.highLevelConcept ? 'concept' : 'field'} title`,
                description: `Enter a description for the ${props.highLevelConcept ? 'concept' : 'field'}`,
            };
        });

        const resetDataModelChoices = () => {
            if (chooseDataModel.value === 'new_concept') {
                selectedModelForConceptToBeCopied.value = null;
                selectedConceptToBeCopied.value = null;

                // reset these if any related terms were added before choosing the import choice and then choosing the new concept choice again
                newConcept.value.relatedTerms = [];
                tag.value = '';
            }
        };

        return {
            addNewRelatedConcept,
            addRelatedConcept,
            cancelAddNewRelatedConcept,
            cancelPrefix,
            changeTags,
            componentKey,
            createConcept,
            disableCreateConcept,
            duplicateAdded,
            editPrefixDescription,
            enableAddNewRelatedConcept,
            filteredActiveHLConcepts,
            hovered,
            loading,
            newConcept,
            newConceptRef,
            newRelatedConcept,
            openAddNewRelatedConcept,
            openEditRelatedConcept,
            placeholderText,
            relatedTermError,
            removeRelatedConcept,
            resetReferenceConcept,
            tag,
            tags,
            types,
            updateMetadata,
            updateStandardsMapping,
            validateReferencePrefix,
            selectedModelForConceptToBeCopied,
            changeModel,
            activeHLConcepts,
            selectedConceptToBeCopied,
            chooseDataModel,
            resetDataModelChoices,
        };
    },
});
