import * as R from 'ramda';
import { ref } from '@vue/composition-api';
import { HighLevelConceptFilters, Status, SuggestionStatus } from '../constants';
import { ModelsAPI } from '../api';

export function useDataModel() {
    const model = ref<any>(null);
    const suggestions = ref<any>([]);
    const highlevelConcepts = ref([]);
    const subConcepts = ref<any>([]);

    /**
     * Defines the metadata object based on concept's datatype
     * @param datatype The datatype of the concept
     */
    const defineDatatypeMetadata = (datatype: string) => {
        const sameMeta = {
            encryption: false,
            sensitive: true,
            multiple: false,
            ordered: false,
            identifier: false,
        };

        switch (datatype) {
            case 'integer':
            case 'double':
                return {
                    ...sameMeta,
                    measurementType: null,
                    measurementUnit: null,
                    spatial: false,
                    index: false,
                };

            case 'string':
                return {
                    ...sameMeta,
                    spatialID: false,
                    spatial: false,
                    index: null, // for dropdown
                };

            case 'datetime':
            case 'date':
            case 'time':
                return {
                    ...sameMeta,
                    temporal: false,
                    index: false, // for toggle
                };

            case 'boolean':
                return {
                    ...sameMeta,
                    index: false, // for toggle
                };

            case 'base64binary':
                return {
                    ...sameMeta,
                    sensitive: false,
                };
            // case 'object':
            default:
                // object datatype
                return {
                    multiple: false,
                    ordered: true,
                    customizable: false,
                };
        }
    };

    /**
     * Deletes any unwanted metadata and adds non-existing ones
     * or replacing string "true" with boolean true
     * (fixes concept's metadata if necessary)
     * @param metadata The metadata of the concept
     * @param type The datatype of the concept
     */
    const fixDatatypeMetadata = (metadata: any, type: string) => {
        const correctMetadata = defineDatatypeMetadata(type);
        const conceptMetadata = R.clone(metadata);

        const keys = Object.keys(conceptMetadata);
        keys.forEach((k: string) => {
            if (!(k in correctMetadata)) {
                delete conceptMetadata[k];
            } else if (conceptMetadata[k] === 'true') {
                conceptMetadata[k] = true;
            }
        });

        if (!conceptMetadata.multiple && conceptMetadata.ordered) {
            conceptMetadata.ordered = false;
        }
        /**  if a metadata exists in both objects, the value maintained in the
         *  merged object comes from the right-most object (e.g. conceptMetadata)
         */
        return { ...correctMetadata, ...conceptMetadata };
    };

    /**
     * Different message is displayed based on the high level concept filter
     * @param filter The filter chosen for high level concepts
     */
    const defineMessageBasedOnFilter = (filter: string) => {
        switch (filter) {
            case HighLevelConceptFilters.Proposed:
                return 'No Proposed High Level Concepts to be edited';
            case HighLevelConceptFilters.Active:
            case Status.Draft:
                return 'Add a High Level Concept to get started';
            case HighLevelConceptFilters.Deprecated:
                return 'No Deprecated High Level Concepts to be viewed';
            default:
                return 'Add a High Level Concept to get started';
        }
    };

    /**
     * Returns the filtered high level concepts or high level proposed concept
     * @param concepts High level concepts or high level proposed concepts
     * @param filter The filter chosen for high level concepts
     * @param searchText Name of high level concept
     */
    const filterConcepts = (activeStatus: any, concepts: any[], filter: string, searchText?: string): any[] => {
        let filtered = [...concepts];
        if (filter === 'active') {
            filtered = R.filter((concept) => concept.status === activeStatus, concepts);
        } else if (filter === 'in review') {
            filtered = R.filter((concept) => concept.status === Status.UnderRevision, concepts);
        } else if (filter === 'draft' || filter === 'new') {
            filtered = R.filter((concept) => concept.status === Status.Draft, concepts);
        } else if (filter === 'deprecated') {
            filtered = R.filter((concept) => concept.status === Status.Deprecated, concepts);
        } else if (filter === 'proposed') {
            filtered = R.filter((concept) => concept.status === SuggestionStatus.Pending, concepts);
        }

        if (searchText) {
            filtered = R.filter(
                (concept) =>
                    R.toLower(concept.name).includes(R.toLower(searchText)) ||
                    R.toLower(concept.description).includes(R.toLower(searchText)),
                concepts,
            );
        }

        return filtered;
    };

    /**
     * Returns the filtered fields or proposed fields of a high level concept
     * @param concepts Fields or proposed fields of a high level concept
     * @param filter The filter chosen for the fields
     */
    const filterFields = (concepts: any[], filter: string): any[] => {
        let filtered = [...concepts];

        if (filter === 'active') {
            filtered = R.filter((concept) => concept.status === Status.Stable, concepts);
        } else if (filter === 'draft' || filter === 'new') {
            filtered = R.filter((concept) => concept.status === Status.Draft, concepts);
        } else if (filter === 'in review') {
            filtered = R.filter((concept) => concept.status === Status.UnderRevision, concepts);
        } else if (filter === 'deprecated') {
            filtered = R.filter((concept) => concept.status === Status.Deprecated, concepts);
        } else if (filter === 'simple') {
            filtered = R.filter((concept) => concept.type !== 'object', concepts);
        } else if (filter === 'related') {
            filtered = R.filter((concept) => concept.type === 'object', concepts);
        } else if (filter === 'proposed') {
            filtered = R.filter((concept) => concept.status === SuggestionStatus.Pending, concepts);
        }

        return filtered;
    };

    /**
     * Returns the high level proposed concepts
     */
    const defineHighlevelSuggestions = () =>
        R.filter((suggestion: any) => suggestion.parentId === null, suggestions.value);

    /**
     * Returns the proposed fields of a high level concept
     * @param selectedHighLevelConceptId The id of the parent of the proposed fields
     */
    const defineSelectedConceptSuggestions = (selectedHighLevelConceptId: any) =>
        R.filter((suggestion: any) => suggestion.parentId === selectedHighLevelConceptId, suggestions.value);

    /**
     * Returns either the filtered high level concepts or the high level proposed concepts
     * @param filter The filter chosen for the high level concepts
     */
    const defineFilteredHLConcepts = (filter: string) => {
        let filteredHLConcepts = [];
        if (filter === HighLevelConceptFilters.Proposed) {
            filteredHLConcepts = filterConcepts(model.value.status, suggestions.value, filter, undefined);
            filteredHLConcepts = filteredHLConcepts.filter((f: any) => f.parentId === null);
        } else {
            filteredHLConcepts = filterConcepts(model.value.status, highlevelConcepts.value, filter, undefined);
        }

        return filteredHLConcepts.sort((a: any, b: any) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
    };

    /**
     * Returns the selected high level filter
     * @param isDraft Whether the model has draft status
     * @param isDeprecated Whether the model has deprecated status
     * @param isHighlevelConceptAProposal Whether a high level concept is a proposed high level concept
     * @param isUnderRevision Whether the model has under revision status
     */
    const defineSelectedHighLevelFilter = (
        isDraft: any,
        isDeprecated: any,
        isHighlevelConceptAProposal: any,
        isUnderRevision: any,
    ) => {
        if (isDraft) {
            return HighLevelConceptFilters.Draft;
        }
        if (isDeprecated) {
            return HighLevelConceptFilters.Deprecated;
        }
        if (isHighlevelConceptAProposal) {
            return HighLevelConceptFilters.Proposed;
        }
        if (isUnderRevision) {
            return HighLevelConceptFilters.All;
        }

        return HighLevelConceptFilters.Active;
    };

    /**
     * Returns the unique saved changes
     * @param savedChanges Saved changes displayed in page
     */
    const defineSavedChanges = (savedChanges: any) => {
        const set: any = new Set();
        return savedChanges.filter((item: any) => {
            const subItem = JSON.stringify(item);

            if (!set.has(subItem)) {
                set.add(subItem);
                return true;
            }
            return false;
        }, set);
    };

    /**
     * Returns the filter options for high level concepts based on the model status
     * @param isDraft Whether the model has draft status
     * @param isDeprecated Whether the model has deprecated status
     * @param isUnderRevision Whether the model has under revision status
     */
    const defineFilterOptions = (isDraft: any, isDeprecated: any, isUnderRevision: any) => {
        if (isDraft || isDeprecated) return [];
        if (isUnderRevision)
            return [
                HighLevelConceptFilters.All,
                HighLevelConceptFilters.UnderRevision,
                HighLevelConceptFilters.New,
                HighLevelConceptFilters.Proposed,
                HighLevelConceptFilters.Deprecated,
            ];

        return [
            HighLevelConceptFilters.Active,
            HighLevelConceptFilters.Proposed,
            HighLevelConceptFilters.Deprecated,
            HighLevelConceptFilters.All,
        ];
    };

    /**
     * Returns existing unsaved changes
     * @param concept The concept (high level concept or sub-concept (i.e. field)) to be checked for any changes
     * @param field The field of the concept that has a change
     * @param value The value of the field with the change
     * @param isFieldConceptAProposal Whether the concept is a proposed field
     * @param isHighlevelConceptAProposal Whether the concept is a proposed high level concept
     * @param editSelectedHighLevelConcept Whether the concept being edited is high level concept
     * @param changesToBeSaved Unsaved changes
     */
    const defineChangesToBeSaved = (
        concept: any,
        field: any,
        value: any,
        isFieldConceptAProposal: boolean,
        isHighlevelConceptAProposal: boolean,
        editSelectedHighLevelConcept: boolean,
        changesToBeSaved: any,
    ) => {
        // don't save changes when editing proposal
        if (!isFieldConceptAProposal && !isHighlevelConceptAProposal) {
            let fieldChange = false;

            if (typeof value === 'object') {
                if (field === 'metadata') {
                    // eslint-disable-next-line no-param-reassign
                    concept[field] = fixDatatypeMetadata(concept.metadata, concept.type);

                    fieldChange = !(JSON.stringify(value) === JSON.stringify(concept[field]));
                } else if (
                    (!concept[field] && value.length) ||
                    (concept[field] && value.length !== concept[field].length)
                ) {
                    // first check is for when standardsMapping/ relatedTerms object is null
                    fieldChange = true;
                } else if (concept[field]) {
                    for (let i = 0; i < concept[field].length; i++) {
                        if (typeof concept[field][i] === 'object') {
                            if (!value.find((v: any) => JSON.stringify(v) === JSON.stringify(concept[field][i]))) {
                                fieldChange = true;
                            }
                        } else if (!value.find((v: any) => v === concept[field][i])) {
                            fieldChange = true;
                        }
                    }
                }
            } else if (concept[field] !== value) {
                fieldChange = true;
            }

            // if there is a change, put the change in the list

            if (fieldChange) {
                const alreadyChanged = !!changesToBeSaved.find(
                    (c: any) => c.concept === concept.id && c.change === field,
                );

                if (!alreadyChanged) {
                    const record = { concept: null, change: null, parentId: null };
                    record.change = field;
                    record.concept = concept.id;
                    record.parentId = !editSelectedHighLevelConcept ? concept.parentId : null;
                    changesToBeSaved.push(record);
                    return changesToBeSaved;
                }
            } else {
                // if the change is equal to the previous value, then remove the change from the list
                return changesToBeSaved.filter((c: any) => c.concept !== concept.id || c.change !== field);
            }
        }

        return changesToBeSaved;
    };

    /**
     * Retrieves the selected high level concept's fields and model's suggestions
     * @param highlevelConceptId The id of the selected high level concept
     */
    const retrieveFields = (highlevelConceptId: number) => {
        return new Promise((resolve, reject) => {
            ModelsAPI.getConcepts(highlevelConceptId)
                .then((res: any) => {
                    subConcepts.value = res.data.sort((a: any, b: any) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    });
                    ModelsAPI.getSuggestions(model.value.id).then((resSuggestions: any) => {
                        suggestions.value = resSuggestions.data.sort((a: any, b: any) => {
                            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                        });
                        resolve('success');
                    });
                })
                .catch((e) => {
                    const errorMessage = e ? e.message : 'Error while retrieving fields';
                    reject(new Error(errorMessage));
                });
        });
    };

    return {
        defineMessageBasedOnFilter,
        defineDatatypeMetadata,
        fixDatatypeMetadata,
        filterConcepts,
        filterFields,
        defineHighlevelSuggestions,
        defineSelectedConceptSuggestions,
        defineFilteredHLConcepts,
        defineSelectedHighLevelFilter,
        defineSavedChanges,
        defineFilterOptions,
        defineChangesToBeSaved,
        retrieveFields,
        model,
        suggestions,
        highlevelConcepts,
        subConcepts,
    };
}
