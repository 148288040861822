






























































































import { defineComponent, computed } from '@vue/composition-api';
import { ButtonGroup, TwButton, Scrollbar, SvgImage } from '@/app/components';
import ConceptOverview from './ConceptOverview.vue';
import { useDataModel } from '../composable';
import { FieldFilters, Status } from '../constants';

export default defineComponent({
    name: 'ConceptContents',

    props: {
        model: {
            type: Object,
            required: true,
        },
        subConcepts: {
            type: Array,
            required: true,
        },
        suggestions: {
            type: Array,
            required: true,
        },
        minimalFilter: {
            type: Boolean,
            default: false,
        },
        deselect: {
            type: Boolean,
            default: false,
        },
        selectCreatedFieldId: {
            type: Number,
            default: null,
        },
        changeToActiveFilter: {
            type: Boolean,
            default: false,
        },
        conceptStatus: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isDraft: {
            type: Boolean,
            required: false,
        },
        isUnderRevision: {
            type: Boolean,
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        changesToBeSaved: {
            type: Boolean,
            default: false,
        },
        savedChanges: {
            type: Array,
            default: () => [],
        },
        selectedId: {
            type: [Number, String],
            default: null,
        },
        selectedConceptStatus: {
            type: String,
            default: null,
        },
        currentFilter: {
            type: String,
            default: null,
        },
    },
    components: {
        TwButton,
        ButtonGroup,
        ConceptOverview,
        Scrollbar,
        SvgImage,
    },
    setup(props, { emit }) {
        // UI variables
        const { filterConcepts, filterFields: filterConceptContents } = useDataModel();

        let filterOptions = [];
        if (props.isDraft) {
            filterOptions = [FieldFilters.Draft, FieldFilters.Simple, FieldFilters.Related];
        } else if (props.isUnderRevision) {
            filterOptions =
                props.conceptStatus === Status.Draft
                    ? [FieldFilters.New, FieldFilters.Simple, FieldFilters.Related]
                    : [
                          FieldFilters.All,
                          FieldFilters.UnderRevision,
                          FieldFilters.New,
                          FieldFilters.Simple,
                          FieldFilters.Related,
                          FieldFilters.Proposed,
                          FieldFilters.Deprecated,
                      ];
        } else {
            filterOptions = [
                FieldFilters.Active,
                FieldFilters.Simple,
                FieldFilters.Related,
                FieldFilters.Proposed,
                FieldFilters.Deprecated,
                FieldFilters.All,
            ];
        }

        if (!props.currentFilter) {
            emit('filter-change', props.conceptStatus === Status.Deprecated ? props.conceptStatus : filterOptions[0]);
        }

        const proposedMode = computed(() => props.currentFilter === FieldFilters.Proposed);

        // Methods
        const filterFields = (selection: any) => {
            emit('filter-change', selection);
            if (props.changeToActiveFilter) {
                emit('deselectActive');
            }
        };

        const filteredFields = computed(() => {
            if (props.conceptStatus === Status.Deprecated) {
                emit('filter-change', Status.Deprecated);
            } else if (props.changeToActiveFilter) {
                if (props.isUnderRevision) {
                    emit('filter-change', props.conceptStatus === Status.Draft ? FieldFilters.New : FieldFilters.All);
                } else if (props.isDraft) {
                    emit('filter-change', FieldFilters.Draft);
                } else {
                    emit('filter-change', FieldFilters.Active);
                }
            } else if (proposedMode.value) {
                return filterConcepts(props.model.status, props.suggestions, props.currentFilter);
            }
            return filterConceptContents(props.subConcepts, props.currentFilter);
        });

        const openHighLevelConcept = (highLevelId: number) => {
            emit('openHighLevelConcept', highLevelId);
        };

        const addField = () => {
            emit('add', false);
        };

        const filterChange = (event: any) => {
            filterFields(event.target.value);
        };

        const checkSelection = (id: number, status: string) => {
            return (
                (props.selectCreatedFieldId &&
                    props.selectCreatedFieldId === id &&
                    props.deselect &&
                    props.selectedConceptStatus === status) ||
                (!props.deselect && props.selectedId === id && props.selectedConceptStatus === status)
            );
        };

        const checkStatus = computed(() => {
            if (props.conceptStatus === Status.Deprecated) return Status.Deprecated;
            if (props.currentFilter === 'all') {
                return '';
            }
            return props.currentFilter;
        });

        return {
            addField,
            checkSelection,
            emit,
            filterChange,
            filteredFields,
            filterFields,
            filterOptions,
            openHighLevelConcept,
            proposedMode,
            Status,
            checkStatus,
        };
    },
});
