var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full mb-4"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Standards Mapping"),(_vm.showStandardsMappingChangeIndication)?_c('change-indication',{attrs:{"saved":_vm.savedChange}}):_vm._e()],1),(_vm.filteredStandardsMapping && _vm.filteredStandardsMapping.length)?_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"flex justify-between w-full text-neutral-600 text-xs py-1 font-semibold"},[_c('span',{staticClass:"w-1/3 mr-2"},[_vm._v("Name")]),_c('span',{staticClass:"w-1/3 text-left mr-2"},[_vm._v("Standard & Version")]),_c('span',{staticClass:"w-3/12 text-left"},[_vm._v("Type")]),(!_vm.readOnly)?_c('span',{staticClass:"w-1/12 text-right"}):_vm._e()]),_c('hr'),_c('ul',{staticClass:"w-full"},_vm._l((_vm.filteredStandardsMapping),function(standardMapping,index){return _c('li',{key:index,staticClass:"px-1 py-1",class:{
                    'cursor-pointer  hover:bg-primary-200':
                        _vm.editStandardsMapping === null && !_vm.addNewStandardsMapping && !_vm.readOnly,
                },on:{"click":function($event){!_vm.addNewStandardsMapping && _vm.editStandardsMapping === null && !_vm.readOnly
                        ? _vm.editStandard(index, standardMapping)
                        : null}}},[_c('validation-observer',{ref:"standardsMappingRef",refInFor:true,staticClass:"w-full flex justify-between items-center",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var invalid = ref.invalid;
return [(_vm.editStandardsMapping === index)?_c('validation-provider',{staticClass:"w-1/3 text-xs leading-5 text-gray-700 mr-2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.name),expression:"standardMapping.name"}],staticClass:"block w-full text-xs form-input text-neutral-700",class:{ 'border-red-500': errors.length },attrs:{"name":"Standards Mapping Name","type":"text","title":"Enter a name for the standards mapping","placeholder":"Enter a name for the standards mapping"},domProps:{"value":(standardMapping.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(standardMapping, "name", $event.target.value)}}})]}}],null,true)}):_c('div',{staticClass:"w-1/3 text-xs leading-5 text-gray-700 mr-2"},[_c('p',{staticClass:"block w-full text-xs truncate",class:standardMapping.name ? 'text-neutral-700' : 'text-neutral-600 italic',staticStyle:{"font-weight":"normal"},attrs:{"title":standardMapping.name}},[_vm._v(" "+_vm._s(standardMapping.name ? standardMapping.name : 'Not provided')+" ")])]),_c('div',{staticClass:"w-1/3 text-xs flex justify-left text-neutral-700 mr-2"},[(_vm.editStandardsMapping === index)?_c('div',[((!_vm.extraStandard || standardMapping.standard === null) && !_vm.newStandard)?_c('div',{staticClass:"flex flex-row space-x-2 justify-left"},[_c('validation-provider',{staticClass:"w-1/2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.standard),expression:"standardMapping.standard"}],staticClass:"block text-xs form-select w-full",class:{
                                            'text-neutral-500': standardMapping.standard === null,
                                            'border-red-500': errors.length,
                                        },attrs:{"title":"Select standard"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(standardMapping, "standard", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.changeStandardResetVersions(index);
                                            _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping);}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select standard")]),_vm._l((_vm.filteredStandards(standardMapping)),function(option){return _c('option',{key:option.standard,domProps:{"value":option.standard}},[_c('span',{staticClass:"text-neutral-700"},[_vm._v(_vm._s(option.standard)+" ")])])}),_c('hr'),_c('option',[_vm._v("Add a new standard")])],2)]}}],null,true)}),_c('validation-provider',{staticClass:"w-1/2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.version),expression:"standardMapping.version"}],staticClass:"block text-xs form-select w-full",class:{
                                            'text-neutral-500': standardMapping.version === null,
                                            'border-red-500': errors.length,
                                        },attrs:{"disabled":!standardMapping.standard,"title":"Select version of the standard"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(standardMapping, "version", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping)}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select version")]),_vm._l((_vm.versionsOfStandard),function(option){return _c('option',{key:option.version,domProps:{"value":option.version}},[_c('span',{staticClass:"text-neutral-700"},[_vm._v(" "+_vm._s(option.version))])])})],2)]}}],null,true)})],1):(_vm.newStandard)?_c('div',{staticClass:"flex justify-left"},[_c('validation-provider',{staticClass:"mr-2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.standard),expression:"standardMapping.standard"},{name:"tooltip",rawName:"v-tooltip",value:(_vm.standardExistsInBothLists ? "Standard already exists" : null),expression:"standardExistsInBothLists ? `Standard already exists` : null"}],staticClass:"block w-full text-xs form-input text-neutral-700 mr-2",class:{
                                            'border-red-500':
                                                (!_vm.userDefinedStandard && errors.length) ||
                                                _vm.standardExistsInBothLists,
                                        },attrs:{"name":"Standard","type":"text","onfocus":(errors = null),"placeholder":"Enter a standard"},domProps:{"value":(standardMapping.standard)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(standardMapping, "standard", $event.target.value)},function($event){return _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping)}],"click":function($event){_vm.userDefinedStandard = false}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.version),expression:"standardMapping.version"}],staticClass:"block w-full text-xs form-input text-neutral-700",class:{ 'border-red-500': errors.length },attrs:{"name":"Standard version","type":"text","placeholder":"Enter a version for the standard"},domProps:{"value":(standardMapping.version)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(standardMapping, "version", $event.target.value)},function($event){return _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping)}]}})]}}],null,true)})],1):_c('div',{staticClass:"flex justify-left"},[_c('validation-provider',{staticClass:"mr-2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.standard),expression:"standardMapping.standard"},{name:"tooltip",rawName:"v-tooltip",value:(_vm.standardExistsInBothLists ? 'Standard already exists' : null),expression:"standardExistsInBothLists ? 'Standard already exists' : null"}],staticClass:"block w-full text-xs form-input text-neutral-700",class:{
                                            'border-red-500': errors.length || _vm.standardExistsInBothLists,
                                        },attrs:{"name":"Standard","type":"text","placeholder":"Enter a standard"},domProps:{"value":(standardMapping.standard)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(standardMapping, "standard", $event.target.value)},function($event){return _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping)}]}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.version),expression:"standardMapping.version"}],staticClass:"block w-full text-xs form-input text-neutral-700",class:{ 'border-red-500': errors.length },attrs:{"name":"Standard version","type":"text","placeholder":"Enter a version for the standard"},domProps:{"value":(standardMapping.version)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(standardMapping, "version", $event.target.value)},function($event){return _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping)}]}})]}}],null,true)})],1)]):_c('span',{staticClass:"w-full flex justify-left"},[_vm._v(" "+_vm._s(standardMapping.standard)+" "+_vm._s(standardMapping.version)+" ")])]),(_vm.editStandardsMapping === index)?_c('validation-provider',{staticClass:"w-3/12 flex justify-left",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(standardMapping.type),expression:"standardMapping.type"},{name:"tooltip",rawName:"v-tooltip",value:("Max number of characters for Type is 15"),expression:"`Max number of characters for Type is 15`"}],staticClass:"w-full text-xs form-input text-neutral-700",class:{ 'border-red-500': errors.length },attrs:{"name":"Standards Mapping Type","type":"text","maxlength":15,"placeholder":"Enter a type"},domProps:{"value":(standardMapping.type)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(standardMapping, "type", $event.target.value)},function($event){return _vm.emit('updatedStandardsMapping', _vm.filteredStandardsMapping)}]}})]}}],null,true)}):(standardMapping.type)?_c('h2',{staticClass:"w-3/12 flex justify-left text-xs font-bold text-neutral-800"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"px-2 text-xs rounded text-neutral-100 bg-neutral-600"},[_vm._v(_vm._s(standardMapping.type))])])]):_c('p',{staticClass:"block w-3/12 text-xs text-neutral-600 italic text-left",staticStyle:{"font-weight":"normal"}},[_vm._v(" Not provided ")]),(!_vm.readOnly)?_c('div',{staticClass:"w-1/12 flex justify-end"},[(_vm.editStandardsMapping === index)?_c('div',{staticClass:"flex justify-between"},[_c('button',{staticClass:"focus:outline-none focus:ring rounded mr-2",attrs:{"title":invalid || _vm.standardAlreadyExists ? 'Resolve existing issues' : 'Done editing',"disabled":invalid || _vm.disableDoneEditing},on:{"click":function($event){_vm.doneEditing(_vm.standardsMapping);
                                    $event.stopPropagation();}}},[_c('svg',{staticClass:"w-4 h-4",class:{ 'mr-4': _vm.collapsedLeftPane },attrs:{"fill":invalid || _vm.disableDoneEditing ? '#68d391' : 'green',"viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","clip-rule":"evenodd"}})])]),_c('button',{staticClass:"focus:outline-none focus:ring rounded",attrs:{"title":"Cancel editing"},on:{"click":function($event){_vm.cancelEditing(index, _vm.standardsMapping);
                                    $event.stopPropagation();}}},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"red","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])]):_c('confirm-button',{staticClass:"my-auto flex items-center",class:_vm.editStandardsMapping !== null && _vm.editStandardsMapping !== index
                                    ? 'pointer-events-none'
                                    : '',on:{"confirmed":function($event){return _vm.removeStandardsMapping(index)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"cursor-pointer text-neutral-500 hover:text-neutral-700 my-auto",attrs:{"title":"Remove"}},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])])]},proxy:true},{key:"confirm",fn:function(){return [_c('div',{staticClass:"cursor-pointer text-orange-600 hover:text-orange-800 my-auto",attrs:{"title":"Click again to confirm deletion"}},[_c('svg',{staticClass:"w-4 h-4 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])])]},proxy:true}],null,true)})],1):_vm._e()]}}],null,true)})],1)}),0)]):_vm._e(),(!_vm.addNewStandardsMapping && !_vm.readOnly)?_c('button',{staticClass:"\n            font-semibold\n            text-sm\n            flex flex-row\n            space-x-2\n            cursor-pointer\n            rounded\n            text-primary-700\n            hover:text-primary-900\n            mt-2\n            focus:outline-none focus:ring\n        ",on:{"click":function($event){_vm.addNewStandardsMapping = true;
            _vm.addStandardsMapping(_vm.standardsMapping);}}},[_c('div',[_vm._v("+ Add a standards mapping")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.requiresAttention && !_vm.addNewStandardsMapping && !_vm.readOnly),expression:"requiresAttention && !addNewStandardsMapping && !readOnly"}],staticClass:"flex flex-row space-x-1 items-center text-xs text-red-500 mt-2"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_c('div',[_vm._v("Standards Mapping requires your attention")])])])}
var staticRenderFns = []

export { render, staticRenderFns }