





































import { defineComponent, ref, computed } from '@vue/composition-api';
import { SvgImage } from '@/app/components';

export default defineComponent({
    name: 'LoadingModal',
    components: { SvgImage },
    props: {
        action: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        let firstInterval: any = null;
        const lastMessage = ref<any>(
            props.action === 'clone-and-copy'
                ? 'Copying concept and any related concepts...'
                : 'Deprecating concept...',
        );
        const message = computed(() => {
            switch (props.action) {
                case 'copy':
                    lastMessage.value = 'Copying concept and any related concepts...';
                    break;
                case 'publish':
                    lastMessage.value = 'Publishing model...';
                    break;
                case 'clone':
                    lastMessage.value = 'Cloning model...';
                    break;
                case 'delete':
                    lastMessage.value = 'Deleting model...';
                    break;
                case 'deprecate':
                    lastMessage.value = 'Deprecating model...';
                    break;
                case 'import':
                    lastMessage.value = 'Importing model, please wait...';
                    break;
                case 'export':
                    lastMessage.value = 'Exporting model, please wait...';
                    break;
                default:
                    // action === 'deprecation' or action === 'clone-and-copy'
                    if (
                        lastMessage.value === 'Deprecating concept...' ||
                        lastMessage.value === 'Copying concept and any related concepts...'
                    ) {
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        firstInterval = setInterval(() => {
                            lastMessage.value = 'Preparing new major version of the model...';
                        }, 5000);
                    }
                    if (lastMessage.value === 'Preparing new major version of the model...') {
                        clearInterval(firstInterval);
                        // eslint-disable-next-line vue/no-async-in-computed-properties
                        setInterval(() => {
                            lastMessage.value = 'Verifying changes...';
                        }, 5000);
                    }
            }

            return lastMessage.value;
        });

        const showImage = computed(() => {
            switch (lastMessage.value) {
                case 'Deprecating concept...':
                    return 1;
                case 'Preparing new major version of the model...':
                    return 2;
                case 'Verifying changes...':
                    return 3;
                default:
                    return 0;
            }
        });

        const loadingImage = computed(() => {
            switch (showImage.value) {
                case 1:
                    return '/img/delete.svg';
                case 2:
                    return '/img/preparation.svg';
                case 3:
                    return '/img/checking.svg';
                default:
                // do nothing
            }

            switch (props.action) {
                case 'publish':
                    return '/img/publish.svg';
                case 'delete':
                case 'deprecate':
                    return '/img/delete.svg';
                case 'copy':
                case 'clone':
                case 'clone-and-copy':
                    return '/img/duplicate.svg';
                case 'import':
                    return '/img/import.svg';
                case 'export':
                    return '/img/export.svg';
                default:
                // do nothing
            }

            return '/img/delete.svg';
        });

        return { message, lastMessage, showImage, loadingImage };
    },
});
