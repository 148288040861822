import { secured } from '@/app/config/axios';
import { CreateModelConcept, UpdateModelConcept } from '../types';

const endpoint = 'data-model';

export default {
    all: () => secured.get(`${endpoint}/all`),
    getModel: (id: number) => secured.get(`${endpoint}/domains/${id}`),
    getConcepts: (id: number) => secured.get(`${endpoint}/domains/${id}/concepts`),
    metadata: (id: number) => secured.get(`${endpoint}/${id}/metadata`),
    publish: (id: number) => secured.put(`${endpoint}/concepts/${id}/publish`),
    delete: (id: number) => secured.delete(`${endpoint}/concepts/${id}`),
    createConcept: (data: any) => secured.post(`${endpoint}/concepts`, data),
    clone: (id: number) => secured.post(`${endpoint}/${id}/clone`),
    updateConcept: (id: number, data: UpdateModelConcept) => secured.put(`${endpoint}/concepts/${id}`, data),
    getSuggestions: (id: number) => secured.get(`${endpoint}/domains/${id}/suggestions`),
    approveSuggestion: (id: number, data: CreateModelConcept) =>
        secured.post(`${endpoint}/suggestions/${id}/approve`, data),
    rejectSuggestion: (id: number) => secured.put(`${endpoint}/suggestions/${id}/reject`),
    getDomainMajorVersion: (uid: string) => secured.get(`${endpoint}/domains/${uid}/major-version`),
    copyConcept: (data: any) => secured.post(`${endpoint}/copy`, data),
    export: (id: number) => secured.get(`${endpoint}/${id}/export`),
    import: (data: any) => secured.post(`${endpoint}/import`, data),
};
