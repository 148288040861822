




















































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { onBeforeRouteLeave } from '@/app/composable/router';
import { ConfirmModal, SvgImage } from '@/app/components';
import {
    ConceptContents,
    CreateConcept,
    EditConcept,
    HighlevelConcepts,
    HighlevelConceptDetails,
    LoadingModal,
    ModelDetails,
} from '../components';
import { useDataModel } from '../composable';
import { HighLevelConceptFilters, Status, SuggestionStatus } from '../constants';
import { ModelsAPI } from '../api';

export default defineComponent({
    name: 'ModelManager',
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    components: {
        ConceptContents,
        ConfirmModal,
        CreateConcept,
        EditConcept,
        HighlevelConceptDetails,
        HighlevelConcepts,
        LoadingModal,
        ModelDetails,
        OrbitSpinner,
        SvgImage,
    },
    setup(props: any, { root }: { root: any }) {
        // UI variables
        const collapsedConcepts = ref(false);
        const conceptsIconHovered = ref(false);
        const openEditPage = ref(false);
        const openCreatePage = ref(false);
        const loading = ref(false);
        const error = ref(null);
        const isDraft = ref(false);
        const isDeprecated = ref(false);
        const isUnderRevision = ref(false);
        const editSelectedHighLevelConcept = ref(true);
        const isHighlevelConceptAProposal = ref(false);
        const isFieldConceptAProposal = ref(false);
        const deprecationLoader = ref<boolean>(false);
        const cloneAndCopyLoader = ref<string>('');
        const readOnly = ref<boolean>(false);
        const changesToBeSaved = ref<any>([]);
        const savedChanges = ref<any>([]);
        const showDiscardChangesModal = ref<boolean>(false);
        const discardChangesModalNext = ref<any>(null);
        const afterDiscardingChanges = ref<any>({
            concept: null,
            proposal: false,
            filter: '',
            highLevel: false,
            callback: null,
        });
        const approvedProposal = ref<boolean>(false);
        const unblockEdit = ref<boolean>(false);
        const discardChanges = ref<boolean>(false);
        const fieldCreation = ref<boolean>(false);
        const deprecatedHLConcept = ref<boolean>(false);
        const createdCopiedHLConcept = ref<boolean>(false);
        const createHighLevelConcept = ref(false);
        const modelMajorVersion = ref<any>(null);
        const notificationParams = ref<any>(root.$route.params ?? null);

        // Selection variables
        const id = computed<number>(() => (R.is(Number, props.id) ? props.id : parseInt(props.id, 10)));
        const selectedHighLevelConcept = ref<any>(null);
        const selectedHighLevelConceptId = ref<any>(null);
        const selectedHighLevelConceptStatus = ref<any>(null);
        const selectedHighlevelFilter = ref<string>(HighLevelConceptFilters.Active);
        const selectedFieldFilter = ref<string | null>(null);
        const selectedConcept = ref<any>(null);
        const selectCreatedFieldId = ref<any>(null);
        const filteredHLConcepts = ref<any>(null);
        const parent = ref<any>(null);
        const selectedFieldId = ref<any>(null);

        const {
            defineMessageBasedOnFilter,
            defineHighlevelSuggestions,
            defineSelectedConceptSuggestions,
            defineFilteredHLConcepts,
            defineSelectedHighLevelFilter,
            defineSavedChanges,
            defineFilterOptions,
            defineChangesToBeSaved,
            retrieveFields,
            model,
            suggestions,
            highlevelConcepts,
            subConcepts,
            filterConcepts,
        } = useDataModel();

        const models = ref<any[]>([]);

        // retrieve all stable (i.e. active) models, but not model's stable self if current model is in under review status
        const stableModels = computed(() => {
            const modelsToBeDisplayed = filterConcepts(Status.Stable, models.value, 'active').filter(
                (m: any) => m.name !== model.value.name,
            );
            return modelsToBeDisplayed.filter((m: any) => m.id.toString() !== props.id.toString());
        });

        const fetchModels = () => {
            loading.value = true;
            ModelsAPI.all().then((res: any) => {
                models.value = res.data;
                loading.value = false;
            });
        };
        fetchModels();

        const highlevelSuggestions = computed(() => defineHighlevelSuggestions());

        const selectedConceptSuggestions = computed(() => {
            return selectedHighLevelConcept.value
                ? defineSelectedConceptSuggestions(selectedHighLevelConcept.value.id)
                : [];
        });

        // Methods
        const refreshFields = (highlevelConceptId: number, afterFieldUpdate = false) => {
            if (!afterFieldUpdate) {
                loading.value = true;
            }

            retrieveFields(highlevelConceptId)
                .then(() => {
                    loading.value = false;
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.message, 'Error');
                });
        };

        /**
         * Sets the action to be executed if they are any unsaved changes or return to the previous page
         */
        const back = () => {
            if (changesToBeSaved.value.length) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.callback = back;
            } else {
                root.$router.go(-1);
            }
        };

        /**
         * Sets the actions to be executed if they are any unsaved changes or sets the new
         * selected high level concept and the appropriate layout of the page
         * (i.e. closes the create concept page and opens the edit concept page)
         * @param concept The high level concept which has been selected
         * @param proposal Whether the selected high level concept is a proposal or not
         */
        const highlevelConceptSelected = (concept: any, proposal: boolean) => {
            if (
                !selectedHighLevelConcept.value ||
                concept.id !== selectedHighLevelConcept.value.id ||
                concept.status !== selectedHighLevelConcept.value.status
            ) {
                if (changesToBeSaved.value.length && !approvedProposal.value) {
                    showDiscardChangesModal.value = true;
                    afterDiscardingChanges.value.concept = concept;
                    afterDiscardingChanges.value.proposal = proposal;
                    afterDiscardingChanges.value.callback = () => {
                        highlevelConceptSelected(
                            afterDiscardingChanges.value.concept,
                            afterDiscardingChanges.value.proposal,
                        );
                    };
                } else {
                    changesToBeSaved.value = [];
                    selectedHighLevelConcept.value = concept;
                    selectedHighLevelConceptId.value = concept ? concept.id : null;
                    selectedHighLevelConceptStatus.value = concept ? concept.status : null;
                    isHighlevelConceptAProposal.value = !!proposal;

                    openEditPage.value = true;
                    openCreatePage.value = false;
                    editSelectedHighLevelConcept.value = true;
                    selectedConcept.value = selectedHighLevelConcept.value;

                    isFieldConceptAProposal.value = false;
                    selectCreatedFieldId.value = null;

                    if (concept && !proposal) {
                        selectedFieldFilter.value = null;
                        refreshFields(concept.id);
                    }
                }
            }
        };

        /**
         * Sets the actions to be executed if they are any unsaved changes or opens
         * a clean Concept/ Field Creator form
         * @param highLevel Whether the concept which will be created is high level or field
         */
        const addConcept = (highLevel: boolean) => {
            if (changesToBeSaved.value.length) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.highLevel = highLevel;
                afterDiscardingChanges.value.callback = () => {
                    addConcept(afterDiscardingChanges.value.highLevel);
                };
            } else {
                openEditPage.value = false;
                openCreatePage.value = false;
                editSelectedHighLevelConcept.value = false;
                selectCreatedFieldId.value = null;
                parent.value = highLevel ? model.value : selectedHighLevelConcept.value;
                createHighLevelConcept.value = highLevel;
                openCreatePage.value = true;
            }
        };

        /**
         * Sets the actions to be executed if they are any unsaved changes or opens the
         * Concept/ Field Details page where a user can edit the concept/ field
         * @param field The field to be edited (not a high level concept)
         * @param proposedField Whether the selected field is a proposal or not
         * @param highLevelConcept Whether concept being edited is a high level concept or field
         */
        const editConcept = (field: any, proposedField: any, highLevelConcept: boolean) => {
            openCreatePage.value = false;
            openEditPage.value = true;
            isFieldConceptAProposal.value = false;

            if (changesToBeSaved.value.length) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.concept = !highLevelConcept ? field : null;
                afterDiscardingChanges.value.proposal = !highLevelConcept ? proposedField : false;
                afterDiscardingChanges.value.highLevel = highLevelConcept;
                afterDiscardingChanges.value.callback = () => {
                    editConcept(
                        afterDiscardingChanges.value.concept,
                        afterDiscardingChanges.value.proposal,
                        afterDiscardingChanges.value.highLevel,
                    );
                };
            } else {
                editSelectedHighLevelConcept.value = !!highLevelConcept;
                if (highLevelConcept) {
                    selectedConcept.value = selectedHighLevelConcept.value;
                    selectCreatedFieldId.value = null;
                } else {
                    selectedFieldId.value = field.id;
                    selectCreatedFieldId.value =
                        field.id === selectCreatedFieldId.value ? selectCreatedFieldId.value : null;
                    if (proposedField) {
                        isFieldConceptAProposal.value = true;
                    }
                    isHighlevelConceptAProposal.value = false;
                    selectedConcept.value = field;
                }
            }
        };

        const clearSelectedConcept = () => {
            selectedHighLevelConcept.value = null;
            selectedHighLevelConceptId.value = null;
            selectedHighLevelConceptStatus.value = null;
            selectedConcept.value = null;
        };

        const filterHLConcepts = (filter: string) => {
            filteredHLConcepts.value = defineFilteredHLConcepts(filter);
            if (filteredHLConcepts.value.length) {
                // if there are any high level concepts, select the first
                highlevelConceptSelected(
                    filteredHLConcepts.value[0],
                    selectedHighlevelFilter.value === HighLevelConceptFilters.Proposed,
                );
            } else {
                openEditPage.value = false;
                openCreatePage.value = false;
                clearSelectedConcept();
                editSelectedHighLevelConcept.value = false;
            }
        };

        /**
         * Retrieves the model, its high level concepts and proposed concepts/fields
         * and sets the high level concepts filter
         * @param defaultSelection The field to be edited (not a high level concept)
         * @param afterFieldUpdate Whether this function is called after a field has been updated
         */
        const refresh = async (afterFieldUpdate = false) => {
            if (unblockEdit.value) {
                unblockEdit.value = false;
            }

            if (!afterFieldUpdate) {
                loading.value = true;
            }
            await ModelsAPI.getModel(id.value).then(async (modelRes: any) => {
                model.value = modelRes.data;
                await ModelsAPI.getDomainMajorVersion(model.value.uid).then((res: any) => {
                    modelMajorVersion.value = res.data.majorVersion;
                });
                isDraft.value = model.value.status === Status.Draft;
                isDeprecated.value = model.value.status === Status.Deprecated;
                isUnderRevision.value = model.value.status === Status.UnderRevision;
                readOnly.value = model.value.majorVersion < modelMajorVersion.value || isDeprecated.value;

                selectedHighlevelFilter.value = defineSelectedHighLevelFilter(
                    isDraft.value,
                    isDeprecated.value,
                    isHighlevelConceptAProposal.value,
                    isUnderRevision.value,
                );

                await ModelsAPI.getConcepts(model.value.id).then((conceptsRes: any) => {
                    highlevelConcepts.value = conceptsRes.data;

                    if (deprecatedHLConcept.value || createdCopiedHLConcept.value) {
                        filterHLConcepts(HighLevelConceptFilters.All);

                        if (filteredHLConcepts.value.length) {
                            highlevelConceptSelected(filteredHLConcepts.value[0], false);
                            editConcept(null, false, true);
                        }
                        deprecatedHLConcept.value = false;
                        createdCopiedHLConcept.value = false;
                    }
                });
                await ModelsAPI.getSuggestions(id.value).then((resSuggestions: any) => {
                    suggestions.value = resSuggestions.data.sort((a: any, b: any) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    });
                });

                if (notificationParams.value.suggestionId) {
                    // selectedHighlevelFilter.value = HighLevelConceptFilters.Proposed;
                    let select = 0;

                    if (notificationParams.value.parentConceptName) {
                        select = highlevelConcepts.value.findIndex(
                            (concept: any) => concept.name == notificationParams.value.parentConceptName,
                        );

                        highlevelConceptSelected(highlevelConcepts.value[select], false);
                        selectedFieldFilter.value = HighLevelConceptFilters.Proposed;

                        selectedConcept.value = selectedConceptSuggestions.value.filter(
                            (suggestion: any) => suggestion.id == notificationParams.value.suggestionId,
                        )[0];
                        editConcept(selectedConcept.value, true, false);
                    } else {
                        selectedHighlevelFilter.value = HighLevelConceptFilters.Proposed;
                        select = suggestions.value.findIndex(
                            (suggestion: any) => suggestion.id == notificationParams.value.suggestionId,
                        );
                        highlevelConceptSelected(suggestions.value[select], true);
                    }
                }

                if (!afterFieldUpdate) {
                    loading.value = false;
                    deprecationLoader.value = false;
                }
            });
        };

        const changeId = () => {
            openEditPage.value = false;
            selectedHighlevelFilter.value = HighLevelConceptFilters.Active;
            clearSelectedConcept();
            editSelectedHighLevelConcept.value = false;
            highlevelConcepts.value = [];
            if (deprecatedHLConcept.value || createdCopiedHLConcept.value) {
                refresh();
            }
        };

        watch(
            () => id.value,
            () => changeId,
        );
        changeId();

        const deselectField = computed(() => {
            return !!(openCreatePage.value || editSelectedHighLevelConcept.value || selectCreatedFieldId.value);
        });

        /**
         * Sets the actions to be executed if they are any unsaved changes or sets
         * the new high level concepts filter
         * @param newFilter New high level concepts filter
         */
        const highlevelConceptFilterChange = (newFilter: string) => {
            if (changesToBeSaved.value.length) {
                showDiscardChangesModal.value = true;
                afterDiscardingChanges.value.filter = newFilter;
                afterDiscardingChanges.value.callback = () => {
                    highlevelConceptFilterChange(afterDiscardingChanges.value.filter);
                };
            } else {
                selectedHighlevelFilter.value = newFilter;
                filterHLConcepts(newFilter);
            }
        };

        const changeHighLevelConcept = (highLevelId: number) => {
            const concept = highlevelConcepts.value
                ? highlevelConcepts.value.find((c: any) => c.id === highLevelId)
                : null;
            highlevelConceptSelected(concept, false);
        };

        const createdHLConcept = (concept: any) => {
            refresh();
            highlevelConceptSelected(concept, false);
            selectedHighlevelFilter.value = isUnderRevision.value
                ? HighLevelConceptFilters.Draft
                : HighLevelConceptFilters.Active;
        };

        const createdField = (concept: any) => {
            refresh();
            selectCreatedFieldId.value = concept.id;
            refreshFields(selectedHighLevelConceptId.value);
            fieldCreation.value = true; // to choose the active filter of fields
            editConcept(concept, false, false);
        };

        const deprecatedConcept = async () => {
            if (!editSelectedHighLevelConcept.value || isDraft.value || isUnderRevision.value) {
                refresh();
                openEditPage.value = false;
                if (isUnderRevision.value) {
                    selectedHighlevelFilter.value = 'all';
                } else {
                    selectedHighlevelFilter.value = HighLevelConceptFilters.Active;
                }
            }

            if (!editSelectedHighLevelConcept.value) {
                // deprecate Field
                refreshFields(selectedHighLevelConceptId.value);
                selectCreatedFieldId.value = null;
                editConcept(null, false, true);
            } else if (isDraft.value || isUnderRevision.value) {
                // deprecate HL Concept
                loading.value = true;
                await ModelsAPI.getConcepts(model.value.id).then((res: any) => {
                    highlevelConcepts.value = res.data;
                    if (isUnderRevision.value) {
                        filterHLConcepts(HighLevelConceptFilters.All);
                    } else {
                        filterHLConcepts(HighLevelConceptFilters.Draft);
                    }
                    if (filteredHLConcepts.value.length) {
                        highlevelConceptSelected(filteredHLConcepts.value[0], false);
                        editConcept(null, false, true);
                    } else {
                        clearSelectedConcept();
                        editSelectedHighLevelConcept.value = false;
                    }
                    loading.value = false;
                });
            } else {
                deprecatedHLConcept.value = true;
            }
        };

        const saveChanges = () => {
            savedChanges.value = defineSavedChanges([...savedChanges.value, ...changesToBeSaved.value]);
            changesToBeSaved.value = [];
        };

        const updateConcept = (concept: any) => {
            saveChanges();
            selectedHighLevelConcept.value = editSelectedHighLevelConcept.value
                ? concept
                : selectedHighLevelConcept.value;
            selectedConcept.value = concept;

            refresh(true);

            if (!editSelectedHighLevelConcept.value) {
                refreshFields(selectedHighLevelConceptId.value, true);
                fieldCreation.value = true;
            }
        };

        const createProposedConcept = async (concept: any) => {
            loading.value = true;
            await ModelsAPI.approveSuggestion(concept.id, concept)
                .then((res: any) => {
                    (root as any).$toastr.s(
                        `${isHighlevelConceptAProposal.value ? 'Proposed Concept' : 'Proposed Field'} '${
                            selectedConcept.value.name
                        }' is approved and created successfully`,
                        'Success',
                    );
                    if (isHighlevelConceptAProposal.value) {
                        approvedProposal.value = true;
                        isHighlevelConceptAProposal.value = false;
                        createdHLConcept(res.data);
                    } else {
                        createdField(res.data);
                    }
                })
                .catch((e) => {
                    (root as any).$toastr.e(
                        `${isHighlevelConceptAProposal.value ? 'Proposed Concept' : 'Proposed Field'} '${
                            selectedConcept.value.name
                        }' failed to be approved and created: ${e.response.data.message}`,
                        'Error',
                    );
                    error.value = e;
                })
                .finally(() => {
                    loading.value = false;
                    approvedProposal.value = false;
                });
        };

        const rejectProposedConcept = () => {
            refresh();
            openEditPage.value = false;
            if (isHighlevelConceptAProposal.value) {
                // choose the first proposed concept
                const proposedHLConcept = suggestions.value.find(
                    (c: any) =>
                        c.status === SuggestionStatus.Pending &&
                        R.isNil(c.parentId) &&
                        c.id !== selectedConcept.value.id,
                );

                if (proposedHLConcept) {
                    highlevelConceptSelected(proposedHLConcept, true);
                    editConcept(null, false, true);
                    selectedHighlevelFilter.value = HighLevelConceptFilters.Proposed;
                } else {
                    clearSelectedConcept();
                    editSelectedHighLevelConcept.value = false;
                    selectedHighlevelFilter.value = HighLevelConceptFilters.Active;
                }
            } else {
                if (selectedConcept.value) {
                    const currentConcept = highlevelConcepts.value.find(
                        (c: any) => c.id === selectedConcept.value.parentId,
                    );

                    highlevelConceptSelected(currentConcept, false);
                    editConcept(null, false, true);
                } else {
                    clearSelectedConcept();
                    editSelectedHighLevelConcept.value = false;
                }

                selectedHighlevelFilter.value = HighLevelConceptFilters.Active;
            }
        };

        const filterOptions = computed(() =>
            defineFilterOptions(isDraft.value, isDeprecated.value, isUnderRevision.value),
        );

        const messageBasedOnFilter = computed(() => {
            return selectedHighlevelFilter.value
                ? defineMessageBasedOnFilter(selectedHighlevelFilter.value)
                : 'Add a High Level Concept to get started';
        });

        const deprecationLoading = (activate: boolean) => {
            deprecationLoader.value = activate;
        };

        const change = (field: any, value: any, isModelConcept = false) => {
            const concept = isModelConcept ? model.value : selectedConcept.value;
            changesToBeSaved.value = defineChangesToBeSaved(
                concept,
                field,
                value,
                isFieldConceptAProposal.value,
                isHighlevelConceptAProposal.value,
                editSelectedHighLevelConcept.value,
                changesToBeSaved.value,
            );
        };

        const confirmDiscardChanges = () => {
            changesToBeSaved.value = [];
            showDiscardChangesModal.value = false;

            if (discardChangesModalNext.value) {
                discardChangesModalNext.value();
                discardChangesModalNext.value = null;
            } else {
                afterDiscardingChanges.value.callback();
                afterDiscardingChanges.value = {
                    concept: null,
                    proposal: false,
                    filter: '',
                    highLevel: false,
                    callback: null,
                };
            }
        };

        onBeforeRouteLeave((to: any, from: any, next: any) => {
            if (changesToBeSaved.value.length > 0) {
                showDiscardChangesModal.value = true;
                discardChangesModalNext.value = next;
            } else {
                next();
            }
        });

        const cloneAndCopyLoading = (action: string) => {
            cloneAndCopyLoader.value = action;
        };

        refresh();

        return {
            addConcept,
            afterDiscardingChanges,
            back,
            change,
            changeHighLevelConcept,
            changesToBeSaved,
            collapsedConcepts,
            conceptsIconHovered,
            confirmDiscardChanges,
            createdField,
            createdHLConcept,
            createHighLevelConcept,
            createProposedConcept,
            deprecatedConcept,
            deprecationLoader,
            deprecationLoading,
            deselectField,
            discardChanges,
            discardChangesModalNext,
            editConcept,
            editSelectedHighLevelConcept,
            error,
            fieldCreation,
            filteredHLConcepts,
            filterOptions,
            highlevelConceptFilterChange,
            highlevelConcepts,
            highlevelConceptSelected,
            highlevelSuggestions,
            isDraft,
            isFieldConceptAProposal,
            isHighlevelConceptAProposal,
            isUnderRevision,
            loading,
            messageBasedOnFilter,
            model,
            modelMajorVersion,
            openCreatePage,
            openEditPage,
            parent,
            readOnly,
            refresh,
            rejectProposedConcept,
            saveChanges,
            savedChanges,
            selectedFieldFilter,
            selectedHighLevelConcept,
            selectedHighLevelConceptId,
            selectedHighlevelFilter,
            selectCreatedFieldId,
            selectedConcept,
            selectedConceptSuggestions,
            selectedFieldId,
            selectedHighLevelConceptStatus,
            showDiscardChangesModal,
            Status,
            subConcepts,
            suggestions,
            unblockEdit,
            updateConcept,
            stableModels,
            createdCopiedHLConcept,
            cloneAndCopyLoading,
            cloneAndCopyLoader,
        };
    },
});
