














































































































































































































































































import * as R from 'ramda';
import { defineComponent, computed, watch, reactive, ref } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { MultivalueEditor, VersionInput } from '@/app/components';
import { Status } from '../constants';
import ChangeIndication from './ChangeIndication.vue';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

export default defineComponent({
    name: 'EditModel',
    model: {
        prop: 'model',
        event: 'update-model',
    },
    props: {
        model: {
            type: Object,
        },
        createModel: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
        },
        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChanges: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ChangeIndication,
        MultivalueEditor,
        ValidationObserver,
        ValidationProvider,
        VersionInput,
    },
    setup: (props: any, { emit }) => {
        const modelClone = reactive({
            name: null,
            description: null,
            standardsMapping: [],
            ...R.clone(props.model),
        });

        const standardsMappingRef = ref<any>(false);
        const canSaveStandardsMapping = ref<boolean>(false);

        const hasChange = computed(() => {
            if (JSON.stringify(modelClone) !== JSON.stringify(props.model)) {
                return new Date();
            }
            return null;
        });

        watch(
            () => hasChange.value,
            (change: any) => {
                if (change) {
                    emit('update-model', modelClone);
                }
            },
        );

        watch(
            () => [modelClone.name, modelClone.description, modelClone.standardsMapping],
            () => {
                emit('clear-error');
            },
        );

        const statuses = [Status.Draft, Status.Stable, Status.Deprecated];

        const saved = ref({
            name: false,
            description: false,
            standardsMapping: false,
        });

        const savedItems = computed(() => {
            const conceptFields = ['name', 'description', 'standardsMapping'];

            conceptFields.forEach((s: any) => {
                saved.value[s] =
                    !!props.savedChanges.find((c: any) => c.concept === props.model.id && c.change === s) &&
                    !props.changesToBeSaved.find((c: any) => c.concept === props.model.id && c.change === s);
            });

            return saved.value;
        });

        const standardsMappingFormChange = async () => {
            if (standardsMappingRef.value) {
                canSaveStandardsMapping.value = await standardsMappingRef.value.validate();
            } else {
                canSaveStandardsMapping.value = false;
            }
        };

        const change = (updatedStandardsMappings: any) => {
            emit('change', 'standardsMapping', updatedStandardsMappings);
        };

        const showNameChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find((c: any) => c.concept === props.model.id && c.change === 'name') ||
                savedItems.value.name
            );
        });

        const showDescriptionChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find((c: any) => c.concept === props.model.id && c.change === 'description') ||
                savedItems.value.description
            );
        });

        const showStandardsMappingChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find(
                    (c: any) => c.concept === props.model.id && c.change === 'standardsMapping',
                ) || savedItems.value.standardsMapping
            );
        });

        return {
            change,
            emit,
            hasChange,
            modelClone,
            savedItems,
            showDescriptionChangeIndication,
            showNameChangeIndication,
            showStandardsMappingChangeIndication,
            statuses,
            standardsMappingRef,
            canSaveStandardsMapping,
            standardsMappingFormChange,
        };
    },
});
