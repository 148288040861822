





















































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { ButtonGroup, Scrollbar, SvgImage } from '@/app/components';
import HighlevelConcept from './HighlevelConcept.vue';
import { useDataModel } from '../composable';
import { Status } from '../constants';

export default defineComponent({
    name: 'HighlevelConcepts',
    props: {
        model: { type: Object, required: true },
        concepts: Array,
        suggestions: Array,
        selectedId: Number,
        selectedStatus: String,
        selectedFilter: String,
        filterOptions: Array,
        readOnly: { type: Boolean, default: false },
        changesToBeSaved: { type: Array, default: () => [] },
        savedChanges: { type: Array, default: () => [] },
    },
    components: { ButtonGroup, HighlevelConcept, Scrollbar, SvgImage },
    setup(props: any, { emit }: { emit: any }) {
        // UI variables
        const collapsedConcepts = ref<boolean>(false);
        const conceptsIconHovered = ref<boolean>(false);
        const searchHighLevelConceptsText = ref('');

        const proposedMode = computed(() => props.selectedFilter === 'proposed');

        const { filterConcepts: filterHLConcepts } = useDataModel();

        const filteredConcepts = computed(() => {
            let highlevelConcepts: any[];
            if (proposedMode.value) {
                highlevelConcepts = filterHLConcepts(
                    props.model.status,
                    props.suggestions,
                    props.selectedFilter,
                    searchHighLevelConceptsText.value,
                );
            } else {
                highlevelConcepts = filterHLConcepts(
                    props.model.status,
                    props.concepts,
                    props.selectedFilter,
                    searchHighLevelConceptsText.value,
                );
            }

            return highlevelConcepts.sort((a: any, b: any) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });
        });

        const filterConcepts = (filter: string) => {
            emit('filter-change', filter);
        };

        const conceptSelected = (concept: any) => {
            emit('selected', concept, proposedMode.value);
        };

        const selection = computed(() => {
            if (R.isNil(props.selectedId) && R.isNil(props.selectedStatus) && filteredConcepts.value.length > 0) {
                const newConcept = filteredConcepts.value.length > 0 ? filteredConcepts.value[0] : null;
                conceptSelected(newConcept);
                return newConcept;
            }

            for (let i = 0; i < filteredConcepts.value.length; i++) {
                const newConcept = filteredConcepts.value[i];
                if (newConcept.id === props.selectedId && newConcept.status === props.selectedStatus) {
                    conceptSelected(newConcept);
                    return newConcept;
                }
            }
            return null;
        });

        const addConcept = () => {
            emit('add', true);
        };

        const filterChange = (event: any) => {
            filterConcepts(event.target.value);
        };

        return {
            addConcept,
            collapsedConcepts,
            conceptSelected,
            conceptsIconHovered,
            emit,
            filterChange,
            filterConcepts,
            filteredConcepts,
            proposedMode,
            searchHighLevelConceptsText,
            selection,
            Status,
        };
    },
});
