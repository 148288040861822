






























import { defineComponent, computed } from '@vue/composition-api';
import { Status, SuggestionStatus } from '../constants';
import ChangeIndication from './ChangeIndication.vue';

export default defineComponent({
    name: 'HighlevelConcept',
    props: {
        concept: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        isProposal: {
            type: Boolean,
            default: false,
        },
        parentStatus: {
            type: String,
            required: true,
        },
        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChanges: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ChangeIndication,
    },
    setup(props: any, { emit }: { emit: any }) {
        const savedHLConcept = computed(
            () =>
                !!props.savedChanges.find(
                    (s: any) =>
                        (s.parentId === props.concept.id || s.concept === props.concept.id) &&
                        props.concept.status !== SuggestionStatus.Pending,
                ),
        );
        const unsavedChanges = computed(
            () =>
                !!props.changesToBeSaved.find(
                    (s: any) =>
                        (s.parentId === props.concept.id || s.concept === props.concept.id) &&
                        props.concept.status !== SuggestionStatus.Pending,
                ),
        );

        return { emit, savedHLConcept, Status, unsavedChanges };
    },
});
