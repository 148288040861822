



































































































































































































































































































































































































































































































































































































import * as R from 'ramda';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import VueTagsInput from '@johmun/vue-tags-input';
import { Card, ConfirmButton, ConfirmModal, Scrollbar, TwButton } from '@/app/components';
import ChangeIndication from './ChangeIndication.vue';
import Metadata from './Metadata.vue';
import StandardsMapping from './StandardsMapping.vue';
import { useDataModel } from '../composable';
import { ModelsAPI } from '../api';
import { Status, SuggestionStatus } from '../constants';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

export default defineComponent({
    name: 'EditConcept',
    props: {
        selectedItem: {
            type: Object,
            required: true,
        },
        standards: {
            type: Array,
            required: false,
        },
        highLevelConcept: {
            type: Boolean,
            default: false,
        },
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        isProposal: {
            type: Boolean,
            default: false,
        },
        isDraft: {
            type: Boolean,
            default: false,
        },
        isUnderRevision: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        changesToBeSaved: {
            type: Array,
            required: true,
        },
        savedChanges: {
            type: Array,
            required: true,
        },
        refreshSelection: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Card,
        ChangeIndication,
        ConfirmButton,
        ConfirmModal,
        Scrollbar,
        StandardsMapping,
        TwButton,
        ValidationProvider,
        ValidationObserver,
        VueTagsInput,
        Metadata,
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        // UI variables
        const selectedConcept = ref<any>(null);
        const hovered = ref(false);
        const editPrefixDescription = ref<any>(null);
        let key = 0;
        const newRelatedConcept = ref({ prefix: null, description: null });
        const cancelPrefix = ref({ prefix: null, description: null });
        const addNewRelatedConcept = ref(false);
        const loading = ref(false);
        const relatedTermError = ref<string | null>(null);
        const tag = ref('');
        const showDeprecateModal = ref(false);
        const conceptRef = ref<any>(null);
        const initialMetadata = ref<any>(null);
        const isConceptDraft = ref<boolean>(false);
        const conceptStatus = ref<string>(Status.Stable);
        const validateReferencePrefix = ref<boolean>(false);
        const validateStandardsMapping = ref<boolean>(false);
        const saved = ref({
            name: false,
            description: false,
            standardsMapping: false,
            relatedTerms: false,
            referencePrefix: false,
            metadata: false,
        });

        const { fixDatatypeMetadata } = useDataModel();

        const selectedItemChanged = (args: any) => {
            selectedConcept.value = R.clone({ ...args }['0']);
            validateReferencePrefix.value = false;
            if (props.isProposal && selectedConcept.value.type === 'object' && !props.highLevelConcept) {
                selectedConcept.value.referencePrefix = [];
                addNewRelatedConcept.value = true;
            } else if (
                selectedConcept.value.referencePrefix &&
                selectedConcept.value.type === 'object' &&
                !props.highLevelConcept
            ) {
                validateReferencePrefix.value = !!selectedConcept.value.referencePrefix.length;
                addNewRelatedConcept.value = false;
            } else {
                validateReferencePrefix.value = true;
                addNewRelatedConcept.value = false;
            }
            validateStandardsMapping.value = true;

            initialMetadata.value = props.isProposal
                ? { sensitive: true, multiple: false, ordered: true }
                : R.clone(selectedConcept.value.metadata);
            editPrefixDescription.value = null;
            newRelatedConcept.value = { prefix: null, description: null };
            cancelPrefix.value = { prefix: null, description: null };
            tag.value = '';
            relatedTermError.value = '';
            showDeprecateModal.value = false;
            isConceptDraft.value = props.isUnderRevision && selectedConcept.value.status === Status.Draft;
            conceptStatus.value = selectedConcept.value.status;
            if (conceptRef.value !== null) {
                conceptRef.value.reset();
            }
        };

        watch(
            () => [props.selectedItem, props.refreshSelection],
            (args) => {
                selectedItemChanged(args);
            },
        );
        selectedItemChanged([props.selectedItem, props.refreshSelection]);

        const changeTags = (newTags: any) => {
            relatedTermError.value = null;
            if (selectedConcept.value) {
                selectedConcept.value.relatedTerms = newTags.map((t: any) => t.text); // eslint-disable-line no-param-reassign
                emit('change', 'relatedTerms', selectedConcept.value.relatedTerms);
            }
        };

        const tags = computed(() => {
            const tmpArray = [] as any;
            if (selectedConcept.value && selectedConcept.value.relatedTerms) {
                selectedConcept.value.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));
            }
            relatedTermError.value = !tag.value ? '' : relatedTermError.value;

            return tmpArray;
        });

        const duplicateAdded = (entry: { text: string }) => {
            relatedTermError.value = `Related term '${entry.text}' already exists`;
        };

        const showModal = () => {
            showDeprecateModal.value = true;
        };

        const deprecate = () => {
            showDeprecateModal.value = false;
            if (selectedConcept.value) {
                loading.value = true;
                if (!props.isDraft && !props.isUnderRevision && props.highLevelConcept) {
                    emit('deprecation-loading', true);
                }
                ModelsAPI.delete(selectedConcept.value.id)
                    .then((res) => {
                        (root as any).$toastr.s(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${selectedConcept.value.name}' is now ${
                                selectedConcept.value.status === Status.Draft ? 'deleted' : 'deprecated'
                            }`,
                            `${selectedConcept.value.status === Status.Draft ? 'Deleted' : 'Deprecated'}`,
                        );

                        if (res.data && selectedConcept.value.id !== res.data.id) {
                            root.$router.push({ name: 'model-manager:edit', params: { id: res.data.id } });
                        }
                        if (props.isUnderRevision) {
                            emit('deprecate-concept', isConceptDraft);
                        } else {
                            emit('deprecate-concept');
                        }
                    })
                    .catch(() => {
                        (root as any).$toastr.e(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${
                                selectedConcept.value.name
                            }' failed to be ${
                                selectedConcept.value.status === Status.Draft ? 'deleted' : 'deprecated'
                            }`,
                            `${selectedConcept.value.status === Status.Draft ? 'Deleted' : 'Deprecated'}`,
                        );
                    })
                    .then(() => {
                        loading.value = false;
                        if (!props.isDraft && !props.isUnderRevision && props.highLevelConcept) {
                            emit('deprecation-loading', false);
                        }
                    });
            }
        };

        const updateStandardsMapping = (updatedMappings: any) => {
            selectedConcept.value.standardsMapping = updatedMappings; // eslint-disable-line no-param-reassign
        };

        const validateInitialStandardsMapping = computed(() => {
            if (selectedConcept.value.standardsMapping && selectedConcept.value.standardsMapping.length) {
                const issue = selectedConcept.value.standardsMapping.find(
                    (sm: any) => R.isNil(sm.name) || R.isNil(sm.standard) || R.isNil(sm.version) || R.isNil(sm.type),
                );

                if (issue) {
                    return false;
                }
            }

            return true;
        });

        const addRelatedConcept = (relatedConcepts: any) => {
            relatedConcepts.push(newRelatedConcept.value);
            validateReferencePrefix.value = !!relatedConcepts.length;
            newRelatedConcept.value = { prefix: null, description: null };
            addNewRelatedConcept.value = false;
            emit('change', 'referencePrefix', relatedConcepts);
        };

        const removeRelatedConcept = (index: number) => {
            selectedConcept.value.referencePrefix.splice(index, 1);
            hovered.value = false;
            emit('change', 'referencePrefix', selectedConcept.value.referencePrefix);
        };

        const componentKey = computed(() => {
            key += 1;
            return selectedConcept.value ? key : null;
        });

        const updateMetadata = (metadata: any) => {
            if (metadata) {
                selectedConcept.value.metadata = metadata; // eslint-disable-line no-param-reassign
                emit('change', 'metadata', selectedConcept.value.metadata);
            }
        };

        const updateConcept = async () => {
            const valid = await conceptRef.value.validate();
            if (valid && selectedConcept.value) {
                if (!props.highLevelConcept) {
                    selectedConcept.value.metadata = fixDatatypeMetadata(
                        selectedConcept.value.metadata,
                        selectedConcept.value.type,
                    );
                }

                const updatedRelatedTerms = [] as any;
                tags.value.forEach((r: any) => updatedRelatedTerms.push(r.text));

                const payload = {
                    ...selectedConcept.value,
                    relatedTerms: updatedRelatedTerms.length ? updatedRelatedTerms : null,
                    standardsMapping:
                        selectedConcept.value.standardsMapping && selectedConcept.value.standardsMapping.length
                            ? selectedConcept.value.standardsMapping
                            : null,
                };

                loading.value = true;
                ModelsAPI.updateConcept(selectedConcept.value.id, payload)
                    .then((res: any) => {
                        initialMetadata.value = R.clone(selectedConcept.value.metadata);

                        emit('update-concept', res.data);
                        (root as any).$toastr.s(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${
                                selectedConcept.value.name
                            }' is updated successfully`,
                            'Success',
                        );
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(
                            `${props.highLevelConcept ? 'Concept' : 'Field'} '${
                                selectedConcept.value.name
                            }' failed to be updated: ${e.response.data.message}`,
                            'Error',
                        );
                    })
                    .then(() => {
                        loading.value = false;
                    });
            }
        };

        const rejectProposed = () => {
            loading.value = true;
            ModelsAPI.rejectSuggestion(selectedConcept.value.id)
                .then(() => {
                    (root as any).$toastr.s(
                        `${props.highLevelConcept ? 'Proposed Concept' : 'Proposed Field'} '${
                            selectedConcept.value.name
                        }' is rejected successfully`,
                        'Success',
                    );
                    emit('reject-proposed');
                })
                .catch(() => {
                    (root as any).$toastr.e(
                        `${props.highLevelConcept ? 'Proposed Concept' : 'Proposed Field'} '${
                            selectedConcept.value.name
                        }' failed to be rejected`,
                        'Error',
                    );
                })
                .then(() => {
                    loading.value = false;
                });
        };

        const approveProposed = () => {
            selectedConcept.value.parentId = selectedConcept.value.parentId
                ? selectedConcept.value.parentId
                : selectedConcept.value.domainId;
            emit('create-proposed', selectedConcept.value);
        };

        const editedStandardsMapping = (done: boolean) => {
            validateStandardsMapping.value = done;
            if (done) {
                emit(
                    'change',
                    'standardsMapping',
                    !selectedConcept.value.standardsMapping ? [] : selectedConcept.value.standardsMapping,
                );
            }
        };

        const savedItems = computed(() => {
            const conceptFields = [
                'name',
                'description',
                'standardsMapping',
                'referencePrefix',
                'relatedTerms',
                'metadata',
            ];

            conceptFields.forEach((s: any) => {
                saved.value[s] =
                    !!props.savedChanges.find(
                        (c: any) =>
                            c.concept === selectedConcept.value.id &&
                            c.change === s &&
                            selectedConcept.value.status !== SuggestionStatus.Pending,
                    ) &&
                    !props.changesToBeSaved.find(
                        (c: any) =>
                            c.concept === selectedConcept.value.id &&
                            c.change === s &&
                            selectedConcept.value.status !== SuggestionStatus.Pending,
                    );
            });

            return saved.value;
        });

        const showNameChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find(
                    (c: any) => c.concept === selectedConcept.value.id && c.change === 'name',
                ) || savedItems.value.name
            );
        });

        const showDescriptionChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find(
                    (c: any) =>
                        c.concept === selectedConcept.value.id &&
                        c.change === 'description' &&
                        selectedConcept.value.status !== 'pending',
                ) || savedItems.value.description
            );
        });

        const showStandardsMapping = computed(() => {
            return (
                !props.readOnly ||
                (props.readOnly &&
                    selectedConcept.value.standardsMapping &&
                    selectedConcept.value.standardsMapping.length)
            );
        });

        const showRelatedConcept = computed(() => {
            return (
                (selectedConcept.value.type === 'object' && !props.highLevelConcept && !props.readOnly) ||
                (props.readOnly &&
                    selectedConcept.value.referencePrefix &&
                    selectedConcept.value.referencePrefix.length)
            );
        });

        const showRelatedConceptChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find(
                    (c: any) => c.concept === selectedConcept.value.id && c.change === 'referencePrefix',
                ) || savedItems.value.referencePrefix
            );
        });

        const changeRelatedConceptActionsLayout = computed(() => {
            return (
                selectedConcept.value.referencePrefix &&
                selectedConcept.value.referencePrefix.length > 1 &&
                (props.isProposal || props.isDraft || isConceptDraft.value)
            );
        });

        const openEditRelatedConcept = (index: number, referencePrefix: any) => {
            editPrefixDescription.value = index;
            cancelPrefix.value.prefix = referencePrefix.prefix;
            cancelPrefix.value.description = referencePrefix.description;
        };

        const cancelAddNewRelatedConcept = () => {
            addNewRelatedConcept.value = false;
            newRelatedConcept.value.prefix = null;
            newRelatedConcept.value.description = null;
        };

        const showRelatedTermsChangeIndication = computed(() => {
            return (
                !!props.changesToBeSaved.find(
                    (c: any) => c.concept === selectedConcept.value.id && c.change === 'relatedTerms',
                ) || savedItems.value.relatedTerms
            );
        });

        const disableApproveProposalOrUpdate = computed(() => {
            return (
                loading.value ||
                !validateReferencePrefix.value ||
                !validateStandardsMapping.value ||
                !validateInitialStandardsMapping.value
            );
        });

        const placeholderText = computed(() => {
            return {
                name: `Enter ${props.highLevelConcept ? 'concept' : 'field'} title`,
                description: `Enter a description for the ${props.highLevelConcept ? 'concept' : 'field'}`,
            };
        });

        return {
            addNewRelatedConcept,
            addRelatedConcept,
            approveProposed,
            cancelAddNewRelatedConcept,
            cancelPrefix,
            changeTags,
            componentKey,
            conceptRef,
            conceptStatus,
            changeRelatedConceptActionsLayout,
            deprecate,
            disableApproveProposalOrUpdate,
            duplicateAdded,
            editedStandardsMapping,
            editPrefixDescription,
            emit,
            hovered,
            initialMetadata,
            isConceptDraft,
            loading,
            newRelatedConcept,
            openEditRelatedConcept,
            placeholderText,
            rejectProposed,
            relatedTermError,
            removeRelatedConcept,
            savedItems,
            selectedConcept,
            showDeprecateModal,
            showDescriptionChangeIndication,
            showModal,
            showNameChangeIndication,
            showRelatedConcept,
            showRelatedConceptChangeIndication,
            showRelatedTermsChangeIndication,
            showStandardsMapping,
            tag,
            tags,
            updateConcept,
            updateMetadata,
            updateStandardsMapping,
            validateInitialStandardsMapping,
            validateReferencePrefix,
            validateStandardsMapping,
        };
    },
});
