





























































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import VueTagsInput from '@johmun/vue-tags-input';
import { Status } from '../constants';
import ChangeIndication from './ChangeIndication.vue';

export default defineComponent({
    name: 'ConceptOverview',
    components: { ChangeIndication, VueTagsInput },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        isProposal: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        modelStatus: {
            type: String,
            required: true,
        },
        changesToBeSaved: {
            type: Boolean,
            default: false,
        },
        savedChanges: {
            type: Array,
            default: () => [],
        },
    },
    setup(props: any, { emit }) {
        const tag = ref('');
        const tags = computed(() => {
            const tmpArray = [] as any;

            if (props.concept.relatedTerms) {
                props.concept.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));
            }
            return tmpArray;
        });

        const savedField = computed(() => !!props.savedChanges.find((s: any) => s.concept === props.concept.id));

        const checkDatatype = computed(() => (props.concept.type === 'base64binary' ? 'binary' : props.concept.type));

        return {
            emit,
            tag,
            tags,
            savedField,
            Status,
            checkDatatype,
        };
    },
});
